// javascript/controllers/loan_calculator_controller.js
import {Controller} from "stimulus";
import {calculateLoan, getSearchDataFromStorage, saveSearchDataToStorage,} from "../helpers";

export default class extends Controller {
  static targets = [];
  loan_data = {
    available_months: null,
    price: 0,
    interest: 0,
    interval: null,
    min: 0,
    max: 0,
    handling: 0,
    base: 0,
  };
  LOAN_ATTR = "S8wXn4n4HNkTex9X3zZAgM6p";

  connect() {
    this.setData();
    this.calculateData();
  }
  setData() {
    let loan_container = document.getElementById("loan-data-container");
    if (loan_container) {
      this.loan_data = JSON.parse(loan_container.dataset.loanData);
    }
  }
  updateLoanTotal(loan_data, last_payment, months, reductions) {
    let total_element = document.getElementById("loan-data-total-price");
    let total = calculateLoan(loan_data, last_payment, months, reductions);
    if (total_element) {
      total_element.textContent = total;
    }
  }

  setLoanValues(data) {
    let loan_value = getSearchDataFromStorage(this.LOAN_ATTR);
    if (!loan_value) {
      saveSearchDataToStorage(this.LOAN_ATTR, data);
      loan_value = getSearchDataFromStorage(this.LOAN_ATTR);
    }

    this.updateLoanTotal(
      this.loan_data,
      loan_value.last_payment,
      loan_value.months,
      loan_value.deposit
    );
  }
  
  calculateData(event) {
    let {
      available_months,
      price,
      interest,
      interval,
      min,
      max,
      handling,
      base,
    } = this.loan_data;

    let loan_values = getSearchDataFromStorage(this.LOAN_ATTR);
    if (!loan_values) {
      loan_values = { months: 72, deposit: 0, last_payment: 40 };
    }
    if (event) {
      let target = event.target;
      let type = target.dataset.type;
      let val_content = document.getElementById(type);
      if (type === "down_payment") {
        target.max = max;
        target.step = interval;
        val_content.textContent = target.value;
        loan_values.deposit = target.value;
      }

      if (type === "credit_period") {
        val_content.textContent = target.value;
        loan_values.months = target.value;
      }

      if (type === "credit_percentage") {
        val_content.textContent = target.value;
        loan_values.last_payment = target.value;
      }

      this.updateFinancingFormFields(loan_values);
    } else {
      let l_val = document.getElementById("down_payment");
      if (l_val) {
        l_val.textContent = loan_values.deposit;
      }
      let d_input = document.getElementById("down_payment_input");
      if (d_input) {
        d_input.max = max;
        d_input.step = interval;
        d_input.value = loan_values.deposit;
      }

      let c_period = document.getElementById("credit_period");
      let c_p_input = document.getElementById("credit_period_input");
      if (c_period) {
        c_period.textContent = loan_values.months;
      }

      if (c_p_input) {
        c_p_input.value = loan_values.months;
      }

      let c_per = document.getElementById("credit_percentage");
      let lc_val = document.getElementById("credit_percentage_input");
      if (c_per) {
        c_per.textContent = loan_values.last_payment;
      }
      if (lc_val) {
        lc_val.value = loan_values.last_payment;
      }
    }
    // Start new test calculation
    let last_percent_element = document.getElementById("credit_percentage_input");
    let down_payment_element = document.getElementById('down_payment')
    let last_payment_amount_element = document.getElementById('last_payment_amount')
    let car_price = price
    let down_payment = parseFloat(down_payment_element.textContent) || 0;
    let finance_amount = car_price - down_payment;
    const lastPayment = parseInt(finance_amount * last_percent_element.value / 100);
    last_payment_amount_element.textContent = lastPayment;
    // End new test calculation

    saveSearchDataToStorage(this.LOAN_ATTR, loan_values);

    const accountOpenFee = this.loan_data.base
    const monthlyInterestRate = this.loan_data.interest / 100 / 12
    const monthlyPaymentWithFeesAndBalloon = this.calculateMonthlyPaymentWithFeesAndBalloon(
      finance_amount + accountOpenFee,
      monthlyInterestRate,
      loan_values.months,
      this.loan_data.handling,
      lastPayment
    );
    // console.log("monthlyPaymentWithFeesAndBalloon", monthlyPaymentWithFeesAndBalloon)
    let total_element = document.getElementById("loan-data-total-price");
    if (total_element && monthlyPaymentWithFeesAndBalloon) {
      total_element.textContent = parseInt(monthlyPaymentWithFeesAndBalloon)
    }
    // const totalPaymentWithFeesAndBalloon = monthlyPaymentWithFeesAndBalloon * loan_values.months + lastPayment + accountOpenFee
    // console.log("totalPaymentWithFeesAndBalloon", totalPaymentWithFeesAndBalloon)
    const effectiveAprWithBalloon = this.calculateEffectiveInterestRateWithBalloon(
      finance_amount,
      monthlyPaymentWithFeesAndBalloon,
      loan_values.months,
      lastPayment)
    // console.log("effectiveAprWithBalloon",effectiveAprWithBalloon)
    let actual_interest = document.getElementById("actual_total_handling_fees");
    if (actual_interest && effectiveAprWithBalloon) {
      actual_interest.textContent = effectiveAprWithBalloon.toFixed(2);
    }
    
    this.updateFormFields(loan_values);
  }

  updateFormFields(loan_values) {
    let { deposit, months, last_payment } = loan_values;
    let d_input = document.getElementById("loan_selections_deposit");
    let m_input = document.getElementById("loan_selections_months");
    let lp_input = document.getElementById("loan_selections_last_payment");
    if (d_input && m_input && lp_input) {
      d_input.value = deposit;
      m_input.value = months;
      lp_input.value = last_payment;
    }

    let total_handling = document.getElementById("total_handling_fees");
    let actual_interest = document.getElementById("actual_total_handling_fees");
    if (total_handling) {
      total_handling.textContent = this.loan_data.handling * months;
    }
    // Start move to new calculation with balloons
    // if (actual_interest) {
    //   let lp = parseInt(last_payment, 10);
    //   let dep = parseInt(deposit, 10);
    //   let mon = parseInt(months, 10);
    //   let loan_price = typeof this.loan_data.price === 'string' ? parseInt(this.loan_data.price, 10) : this.loan_data.price;
    //   let current_price = loan_price - dep - ((lp / 100) * loan_price);
    //   let monthly_price = parseInt(
    //     calculateLoan(this.loan_data, lp, mon, dep),
    //     10
    //   );
    //   let total = monthly_price * mon;
    //   let total_interest = ((total - current_price) / months) * 12;
    //   let actual_total = (total_interest / this.loan_data.price) * 100;
    //   actual_interest.textContent = (
    //     actual_total + this.loan_data.interest
    //   ).toFixed(2);
    //    (1 - ((this.loan_data.interest/100) / 12) * 100).toFixed(2);

    //   const apr = this.calculateAPR(current_price, mon, monthly_price,
    //       this.loan_data.base, this.loan_data.handling);
    //   if(apr && apr > 0) {
    //     actual_interest.textContent = apr.toFixed(2);
    //   }
    // }
    // End move to new calculation with balloons
  }

  updateFinancingFormFields(loan_values) {
    let { deposit, months, last_payment } = loan_values;
    let d_input = document.getElementById("financing_selected_deposit");
    let m_input = document.getElementById("financing_selected_months");
    let lp_input = document.getElementById("financing_selected_last_payment");
    if (d_input && m_input && lp_input) {
      d_input.value = deposit;
      m_input.value = months;
      lp_input.value = last_payment;
    }
  }

  calculateLeasingKm(e) {
    let slider_value = e.target.value;
    let leasing_price_field = document.getElementById(
      "leasing_monthly_price_field"
    );
    let leasing_price = leasing_price_field.dataset.leasePrice;
    let leasing_km_field = document.getElementById("leasing_km_calc");
    leasing_km_field.textContent = `${slider_value}tkm`;
    let price_per_km = this.sliderValue(parseInt(slider_value, 10));
    let calculated_leasing_price = parseInt(leasing_price, 10) + price_per_km;
    leasing_price_field.textContent = `${calculated_leasing_price} €/kk`;
  }

  sliderValue(value) {
    switch (value) {
      case 10:
        return 0;
      case 15:
        return 25;
      case 20:
        return 50;
      case 25:
        return 75;
      case 30:
        return 100;
      case 35:
        return 125;
    }

    return value;
  }

  calculateAPR(loanAmount, loanPeriodMonths, monthlyPayment, loanOriginationCost, monthlyHandlingCost) {
    const totalOutflow = loanAmount + loanOriginationCost;

    function calcPresentValue(APR) {
      let presentValue = 0;
      for (let month = 1; month <= loanPeriodMonths; month++) {
        presentValue += (monthlyPayment + monthlyHandlingCost) / Math.pow(1 + APR / 1200, month);
      }
      return presentValue;
    }

    let lowerBoundAPR = 0; // Lower bound of APR
    let upperBoundAPR = 100; // Upper bound of APR, 100% seems sufficient for any consumer credit APR
    let midAPR;
    let presentValueMidAPR;

    while (upperBoundAPR - lowerBoundAPR > 1e-7) { // Keep iterating until the range is sufficiently small
      midAPR = (lowerBoundAPR + upperBoundAPR) / 2;
      presentValueMidAPR = calcPresentValue(midAPR);
      if (presentValueMidAPR < totalOutflow) {
        upperBoundAPR = midAPR; // If the present value at midAPR is lower than total outflow, decrease the upper bound
      } else {
        lowerBoundAPR = midAPR; // If it's higher, increase the lower bound
      }
    }
    return midAPR; // This will be sufficiently close to the actual APR
  }

  calculateMonthlyPayment(P, r, n) {
    return (P * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
  }

  calculateMonthlyPaymentWithBalloonCase1(loan_values) {
    const last_payment_amount_element = document.getElementById('last_payment_amount')
    const lastPayment = parseInt(last_payment_amount_element.textContent)
    const downPayment = loan_values.deposit
    const loanMonths = loan_values.months
    const monthlyHandlingFee = this.loan_data.handling
    const accountOpenFee = this.loan_data.base
    const annualInterestRate = this.loan_data.interest
    const carPrice =  this.loan_data.price

    // Calculate the principal loan amount
    const principal = carPrice - downPayment + accountOpenFee;
    // console.log("principal: " + principal);

    // Convert annual interest rate to a monthly rate
    const monthlyInterestRate = (annualInterestRate / 100) / 12;
    // console.log("monthlyInterestRate: " + monthlyInterestRate);

    // Calculate the adjusted principal for last balloon payment
    const adjustedPrincipal = principal - lastPayment / Math.pow((1 + monthlyInterestRate), loanMonths);
    // console.log("adjustedPrincipal: " + adjustedPrincipal);

    // Calculate the monthly payment with adjusted principal
    const monthlyPaymentAdjusted = (adjustedPrincipal * monthlyInterestRate) / (1 - Math.pow((1 + monthlyInterestRate), -loanMonths));
    // console.log("monthlyPaymentAdjusted: " + monthlyPaymentAdjusted);

    // Calculate the total monthly payment including handling fee
    const totalMonthlyPayment = monthlyPaymentAdjusted + monthlyHandlingFee;
  
    // let total_element = document.getElementById("loan-data-total-price");
    // if (total_element && totalMonthlyPayment) {
    //   total_element.textContent = parseInt(totalMonthlyPayment)
    // }
    return totalMonthlyPayment
  }

  calculateMonthlyPaymentWithFeesAndBalloon(P, r, n, fee, balloonPayment) {
    // Calculate the present value of the balloon payment
    const pvBalloonPayment = balloonPayment / Math.pow(1 + r, n);

    // Calculate the effective loan amount that will be financed through regular payments
    const effectiveLoanAmount = P - pvBalloonPayment;

    // Calculate the standard loan monthly payment
    const basePayment = (effectiveLoanAmount * r * Math.pow(1 + r, n)) / (Math.pow(1 + r, n) - 1);
    
    // Return total monthly payment including the fee
    return basePayment + fee; // Add monthly fee to each payment
  }


  calculateEffectiveInterestRateWithBalloon(loanAmount, monthlyPayment, numberOfPayments, balloonPayment) {
    const tolerance = 0.00001; // For precision in finding the rate
    let low = 0.0; // Start of the search range for the monthly interest rate
    let high = 1.0; // End of the search range for the monthly interest rate
    let monthlyRate = 0.0; // Monthly interest rate to be determined

    // Use binary search to find the monthly interest rate
    while (high - low > tolerance) {
        monthlyRate = (low + high) / 2; // Midpoint
        
        // Calculate the present value of the monthly payments and the balloon payment
        const presentValue = (monthlyPayment * (1 - Math.pow(1 + monthlyRate, -numberOfPayments)) / monthlyRate) +
                             (balloonPayment / Math.pow(1 + monthlyRate, numberOfPayments));

        if (presentValue < loanAmount) {
            high = monthlyRate; // Lower the high bound
        } else {
            low = monthlyRate; // Raise the low bound
        }
    }

    // Calculate the effective annual interest rate
    const effectiveAnnualRate = Math.pow(1 + monthlyRate, 12) - 1;
    
    return effectiveAnnualRate * 100; // Return as a percentage
  }
}
