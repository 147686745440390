// javascript/controllers/autolle_carousel_controller.js
import {Controller} from "stimulus";
import Rails from "@rails/ujs";
import {enableDisableFormFields} from "../helpers/index";

export default class extends Controller {
  sell_car_form_container = null;
  simplified_form = null;
  slider = null;
  points = null;
  indicator = null;
  sliderLineBehind = null;
  cards_container = null;
  cards = null;
  cards_section = null;
  currentPointIndex = null;
  cards_overflowed = null;
  input_values = {};
  main = null;
  touchstartX = 0;
  touchendX = 0;
  current_step = 0;
  main_error_message = null;
  sell_request_id = null;
  photos = [];
  form_fields = {
    1: ["service_category", "reg_num", "km", "year", "current_step", "full_name", "email", "phone", "terms_accepted"],
    2: [],
    3: ["current_step", "[ar_car_info][winter_tire_condition]", "[ar_car_info][summer_tire_condition]", "[ar_car_info][fuel]", "[ar_car_info][drive_type]", "[ar_car_info][regular_maintenance]", "[ar_car_info][has_maintenance_book]"],
    4: ["current_step", "[ar_car_info][ar_car_location]", "[ar_car_info][own_assessment]"],
    5: []
  };
  card_info = {};
  matching_services = [];

  connect() {
    this.main = this.element;
    this.simplified_form = this.main.querySelector('.simplified_form_container');
    this.sell_car_form_container = this.main.querySelector('.sell_car_form_container');
    if(this.simplified_form) {
      this.simplifiedFormTags();
    }else if (this.sell_car_form_container){

    }else {
      this.carouselFormTags();
    }
  }

  simplifiedFormTags(){
    this.resetInitialValues();
  }

  showMainForm(event) {
    let empty_fields = ["reg_num", "km"];
    let has_invalid = false;
    let form_container = this.main.querySelector(".carouse-range__ar");

    empty_fields.forEach((field_name) => {
      let input = this.main.querySelector(`input[name="${field_name}"]`);
      if(!input.value){
        input.classList.add("is-invalid");
        has_invalid = true;
      }
    });

    if(has_invalid){
      return;
    }

    let keys = Object.keys(this.input_values);
    if(keys.length > 0){
      let form = this.main.querySelector(".new_ar_form")
      if(form){
        form_container.classList.remove("d-none");
        form_container.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

        let service_category = form.querySelector("input[name='service_category']");
        let reg_num = form.querySelector("input[name='reg_num']");
        let km = form.querySelector("input[name='km']");
        let year = form.querySelector("input[name='year']");
        service_category.value = "AUTORAHAKSI"; //name;
        reg_num.value = this.input_values.reg_num;
        km.value = this.input_values.km;
        year.value = this.input_values.year;
      }
    }
  }

  carouselFormTags(){
    this.slider = this.main.querySelector('.carouse-range__slider');
    this.points = this.main.querySelectorAll('.carouse-range__slider-point');
    this.indicator = this.main.querySelector('.carouse-range__point-indicator');
    this.sliderLineBehind = this.main.querySelector('.carouse-range__slider-line.carouse-range__behind');
    this.cards_container = this.main.querySelector('.carouse-range__cards');
    this.cards = this.main.querySelectorAll('.carouse-range__card');
    this.cards_section = this.main.querySelector('.carouse-range__section');
    this.currentPointIndex = this.get_active_card_index();
    this.cards_overflowed = this.isOverflowed();
    this.main_error_message = this.main.querySelector(".main-error-message");
    this.resetInitialValues();
    this.moveToIndex(this.currentPointIndex);
    this.getCardsInfo();
  }

  getCardsInfo() {
    this.cards.forEach((card) => {
      let btn = card.querySelector("button");
      if(btn) {
        let name = btn.getAttribute("data-name");
        let location = btn.getAttribute("data-location");
        let max_km = btn.getAttribute("data-km");
        let age = btn.getAttribute("data-age");
        let always_available = btn.getAttribute("data-active");
        let index = btn.getAttribute("data-index");
        let title = btn.getAttribute("data-title");
        let disabled = card.getAttribute('data-disabled');
        let no_service = card.getAttribute('data-no-service');
        this.card_info[name] = {index, name, location, max_km, age, always_available, disabled, no_service, title};
      }
    });
  }

  resetInitialValues(){
    let controller = this;
    let form = controller.main.querySelector(".carouse-range__form");
    setTimeout(() => {
      if(form){
        let inputs = ["reg_num", "km", "year"];
        inputs.forEach((input_name) => {
          let input = form.querySelector(`input[name="${input_name}"]`);
          if(input){
            controller.updateInputValueByName(input, input.value, input_name);
          }
        });
      }
    }, 500);
  }

  filterServices(max_km, age) {
    this.matching_services = [];
    for (let key in this.card_info) {
      let service = this.card_info[key];

      let serviceMaxKm = parseInt(service.max_km, 10);
      let serviceAge = parseInt(service.age, 10);

      if (max_km <= serviceMaxKm && age <= serviceAge && service.no_service !== "true") {
        if(this.matching_services.indexOf(service.name) === -1){
          this.matching_services.push(service.name);
        }
      }
    }
  }

  updateStatusAndRecommend(){
    let always_available_services = this.getAlwaysAvailableServices();
    let recommended_services = [];
    if(!this.matching_services || this.matching_services.length <= 0){
      this.matching_services = always_available_services;
    }

    // Update this.cards data attribute disabled true if not in matching_services
    this.cards.forEach((card) => {
      let btn = card.querySelector("button");
      if(btn){
        let name = btn.getAttribute("data-name");
        if (this.matching_services.indexOf(name) === -1){
          card.setAttribute('data-disabled', 'true');
          btn.disabled = true;
        }else {
          card.setAttribute('data-disabled', 'false');
          btn.disabled = false;
          recommended_services.push(parseInt(btn.getAttribute("data-index")));
          this.updateFormTitle(this.card_info[name].title);
        }
      }
    });

    if(recommended_services) {
      this.moveToIndex(Math.min(...recommended_services));
      this.hideMainForm();
    }

  }

  updateFormTitle(title){
    let form_title = document.querySelector(".new_ar_form--title");
    form_title.textContent = title ? `${title} - yhteydenotto` : "Autorahaksi - yhteydenotto";
  }

  getAlwaysAvailableServices(){
    let always_available_services = [];
    for (let key in this.card_info) {
      let service = this.card_info[key];
      if(service.always_available === "true"){
        always_available_services.push(service.name);
      }
    }
    return always_available_services;
  }

  // Function to update the active state of cards
  updateCards(targetIndex) {
    this.cards.forEach((card, index) => {
      let no_service = card.getAttribute('data-no-service'); // No, true
      let disabled = card.getAttribute('data-disabled'); // true, false
      if(no_service === "true"){
        card.classList.remove('active');
      }else if(disabled === "true"){
        card.classList.remove('active');
      }else {
        if (index === targetIndex) {
          card.classList.add('active');
        } else {
          card.classList.remove('active');
        }
      }
    });
  }

  // Function to move the indicator to the point with the specified index
  moveToIndex(targetIndex) {
    let targetPoint = this.points[targetIndex];
    if (!targetPoint) return;
    let targetPointRect = targetPoint.getBoundingClientRect();
    let sliderRect = this.slider.getBoundingClientRect();

    this.indicator.style.transition = 'left 0.3s ease-in-out';
    let leftPos = targetPointRect.left - sliderRect.left + (targetPointRect.width / 2) - (this.indicator.offsetWidth / 2);
    this.indicator.style.left = `${leftPos}px`;
    this.sliderLineBehind.style.width = `${leftPos + (this.indicator.offsetWidth / 2)}px`;
    this.sliderLineBehind.style.height = '10px';
    this.sliderLineBehind.style.transition = 'width 0.3s ease-in-out';

    this.currentPointIndex = targetIndex; // Update the current point index
    this.indicator.setAttribute('data-index', this.currentPointIndex); // Update indicator data-index

    // Update the active state of the cards
    this.updateCards(targetIndex);
    if(this.cards_overflowed) {
      this.scrollToCenter(targetIndex);
    }
  }


  setSelectedPoints(event) {
    let target = event.currentTarget;
    let targetIndex = parseInt(target.getAttribute('data-index'));
    if (targetIndex === this.currentPointIndex) return;
    this.moveToIndex(targetIndex);
  }

  dragMouseAction(event) {
    event.preventDefault();

    let controller = this;
    let startX = event.pageX;
    let startLeft = parseInt(getComputedStyle(controller.indicator).left, 10);

    this.indicator.style.transition = ''; // Disable transition during dragging

    function onMouseMove(event) {
      event.preventDefault();
      let pageX = event.pageX;
      let deltaX = pageX - startX;
      adjustIndicatorPosition(deltaX);
    }

    function onMouseUp(event) {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
      finalizePosition(event.pageX);
    }

    function adjustIndicatorPosition(deltaX) {
      let newLeft = startLeft + deltaX;
      controller.updateIndicatorAndBackground(newLeft);
    }

    function finalizePosition(finalPageX) {
      let sliderRect = controller.slider.getBoundingClientRect();
      let indicatorLeft = finalPageX - sliderRect.left + (controller.indicator.offsetWidth / 2);
      controller.moveToClosestIndex(indicatorLeft);
    }

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }

  dragTouchAction(event) {
    event.preventDefault();

    let controller = this;
    let startX = event.touches[0].pageX;
    let startLeft = parseInt(getComputedStyle(controller.indicator).left, 10);

    this.indicator.style.transition = ''; // Disable transition during dragging

    function onTouchMove(event) {
      event.preventDefault();
      let pageX = event.touches[0].pageX;
      let deltaX = pageX - startX;
      adjustIndicatorPosition(deltaX);
    }

    function onTouchEnd(event) {
      document.removeEventListener('touchmove', onTouchMove);
      document.removeEventListener('touchend', onTouchEnd);
      let pageX = event.changedTouches[0].pageX;
      finalizePosition(pageX);
    }

    function adjustIndicatorPosition(deltaX) {
      let newLeft = startLeft + deltaX;
      controller.updateIndicatorAndBackground(newLeft);
    }

    function finalizePosition(finalPageX) {
      let sliderRect = controller.slider.getBoundingClientRect();
      let indicatorLeft = finalPageX - sliderRect.left + (controller.indicator.offsetWidth / 2);
      controller.moveToClosestIndex(indicatorLeft);
    }

    document.addEventListener('touchmove', onTouchMove, { passive: false });
    document.addEventListener('touchend', onTouchEnd);
  }

  updateIndicatorAndBackground(newLeft) {
    let sliderRect = this.slider.getBoundingClientRect();
    newLeft = Math.max(0, Math.min(newLeft, sliderRect.width - this.indicator.offsetWidth));
    this.indicator.style.left = `${newLeft}px`;
    this.sliderLineBehind.style.width = `${newLeft + (this.indicator.offsetWidth / 2)}px`;
    this.sliderLineBehind.style.transition = 'none'; // Disable transition during dragging
  }

  moveToClosestIndex(indicatorLeft) {
    let closestIndex = 0;
    let minDistance = Infinity;

    this.points.forEach(function (point, index) {
      let pointCenter = point.offsetLeft + (point.offsetWidth / 2);
      let distance = Math.abs(pointCenter - indicatorLeft);
      if (distance < minDistance) {
        closestIndex = index;
        minDistance = distance;
      }
    });

    this.moveToIndex(closestIndex); // Move to the nearest point after dragging ends
  }

  isOverflowed() {
    let lastChild = this.cards_container.lastElementChild;
    if (!lastChild) {
      return false;
    }

    let safe_width = 0;
    if(window.innerWidth > 1400){
      safe_width = 100;
    }

    let containerRight = this.cards_container.offsetLeft + this.cards_container.clientWidth;
    let lastChildRight = lastChild.offsetLeft + lastChild.offsetWidth - safe_width;

    return lastChildRight > containerRight;
  }

  scrollToCenter(targetIndex) {
    // Scroll effect
    let targetCard = this.cards[targetIndex];
    const cardWidth = targetCard.offsetWidth;
    const cardIndex = Array.from(this.cards_container.children).indexOf(
        targetCard
    );
    const offset = (this.cards_section.offsetWidth - cardWidth - 65) / 2;
    const scrollPosition =
        cardIndex *
        (cardWidth +
            parseInt(window.getComputedStyle(targetCard).marginRight)) -
        offset;

    this.cards_container.style.transform =
        "translateX(" + -scrollPosition + "px)";
  }

  setSelectedCard(event) {
    let target = event.target
    let parent = target.closest('.carouse-range__card');
    let disabled = "false"
    if(parent){
      disabled = parent.getAttribute('data-disabled');
      let targetIndex = parseInt(parent.getAttribute('data-index'));
      if (targetIndex !== this.currentPointIndex){
        this.moveToIndex(targetIndex);
      }
    }

    if(target.tagName === "BUTTON"){
      this.continueWithSelectedCard(event);
    }else {
      disabled = "true";
    }

    if(disabled === "true"){
      this.hideMainForm();
    }
  }

  hideMainForm(){
    let form_container = this.main.querySelector(".carouse-range__ar");
    if(form_container && !form_container.classList.contains("d-none")){
      form_container.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      form_container.classList.add("d-none");
    }
  }

  continueWithSelectedCard(event) {
    let target = event.target
    let name = target.getAttribute('data-name')
    let title = target.getAttribute('data-title')
    let location = target.getAttribute('data-location')
    let max_km = target.getAttribute('data-km')
    let age = target.getAttribute('data-age')
    let index = target.getAttribute('data-index')
    let always_available = target.getAttribute('data-active')
    let empty_fields = ["reg_num", "km", "year"];
    let form_container = this.main.querySelector(".carouse-range__ar");

    if(location){
      window.location.href = location;
      return;
    }

    empty_fields.forEach((field_name) => {
      let input = this.main.querySelector(`input[name="${field_name}"]`);
      if(!input.value){
        input.classList.add("is-invalid");
      }
    });

    let keys = Object.keys(this.input_values);
    if(keys.length <= 0){
      this.main.querySelector(".carouse-range__main")
          .scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }else {
      let accepted_km = parseInt(this.input_values.km) <= parseInt(max_km);
      let accepted_age = parseInt(this.input_values.year) <= parseInt(age);
      //let span = this.main.querySelector("span[data-bs-target='#arWrongCardInfoModal']");
      if(!accepted_km && always_available !== "true" || !accepted_age && always_available !== "true"){
        this.setAvailableCard();
        if(!form_container.classList.contains("d-none")){
          form_container.classList.add("d-none");
        }
      }else {
        let form = this.main.querySelector(".new_ar_form")
        if(form){
          form_container.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
          if(this.matching_services.indexOf(name) === -1){
            form_container.classList.add("d-none");
          }else {
            form_container.classList.remove("d-none");
          }

          this.updateFormTitle(title);

          let service_category = form.querySelector("input[name='service_category']");
          let reg_num = form.querySelector("input[name='reg_num']");
          let km = form.querySelector("input[name='km']");
          let year = form.querySelector("input[name='year']");
          service_category.value = name;
          reg_num.value = this.input_values.reg_num;
          km.value = this.input_values.km;
          year.value = this.input_values.year;
        }
      }
    }
  }

  setAvailableCard() {
    let active_btn = this.main.querySelector(".carouse-range__card--btn[data-active='true']");
    if(active_btn){
      let index = parseInt(active_btn.getAttribute("data-index"));
      this.moveToIndex(index);
    }
  }

  updateInputValues(event) {
    let target = event.target;
    let value = target.value;
    let name = target.name;
    this.updateInputValueByName(target, value, name);
  }

  updateInputValueByName(target, value, name){
    this.input_values[name] = value;
    if(target.classList.contains("is-invalid")){
      target.classList.remove("is-invalid");
    }

    if(this.input_values.reg_num && this.input_values.km && this.input_values.year){
      this.filterServices(parseInt(this.input_values.km), parseInt(this.input_values.year));
      this.updateStatusAndRecommend();
    }
  }

  get_active_card_index(){
    let active_index = 0;
    this.cards.forEach((card) => {
      if(card.classList.contains("active")){
        active_index = parseInt(card.getAttribute("data-index"));
      }
    });

    return active_index;
  }

  resetInputValues() {
    let empty_fields = ["reg_num", "km", "year"];
    empty_fields.forEach((field_name) => {
      let input = this.main.querySelector(`input[name="${field_name}"]`);
      input.value = "";
    });
  }

  startSwipe(event) {
    this.touchstartX = event.changedTouches[0].screenX;
    this.touchstartY = event.changedTouches[0].screenY;
  }

  endSwipe(event) {
    this.touchendX = event.changedTouches[0].screenX;
    this.touchendY = event.changedTouches[0].screenY;

    const diffX = this.touchendX - this.touchstartX;
    const diffY = this.touchendY - this.touchstartY;

    // Check if the swipe is more horizontal than vertical
    if (Math.abs(diffX) > Math.abs(diffY)) {
      // It's a horizontal swipe
      if (diffX < 0 && this.currentPointIndex < this.cards.length) {
        // Swipe was left
        this.moveToIndex(this.currentPointIndex + 1);
      } else if (diffX > 0 && this.currentPointIndex > 0) {
        // Swipe was right
        this.moveToIndex(this.currentPointIndex - 1);
      }
    }
  }

  showImportedMessage(event){
    let target = event.target;
    let message = document.querySelector(".imported_car_message");
    let submit_btn = document.querySelector(".ar_submit_btn_1");
    if(target.checked){
      message.classList.remove("d-none");
      submit_btn.classList.add("d-none", "disabled");
    }else {
      message.classList.add("d-none");
      submit_btn.classList.remove("d-none", "disabled");
    }
  }

  submitSellRequest(form, request_type="post") {
    let form_url = request_type === 'post' ? form.action : form.action + `/${this.sell_request_id}`;
    let form_data = new FormData(form);
    let controller = this;
    Rails.ajax({
      type: request_type,
      url: form_url,
      data: form_data,
      success: (response) => {
        let { status, step, id, data} = response;
        if (status === "SUCCESS") {
          controller.activateStep(parseInt(step), data);
          controller.sell_request_id = id;
        }
      },
      error: (response) => {
        let {step} = response;
        controller.showPopUpErrorInfo("Virhe lähettämisessä. Tarkista kaikki kentät ja yritä uudelleen.");
        controller.enableSubmitBtn(step)
      },
    });
  }

  activateStep(step, data){
    if(step && step < 4){
      let id = `.carouse-range__ar--step-${step}`
      let step_element = this.main.querySelector(id);
      if(step_element){
        step_element.innerHTML = data;
        if(step_element.classList.contains("d-none")){
          step_element.classList.remove("d-none");
        }
      }
      this.current_step = step;
      step_element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      let submit_btn = this.main.querySelector(`.ar_submit_btn_${step}`);
      if(submit_btn){
        submit_btn.classList.add("d-none", "disabled");
      }
    } else {
      let step_element = this.main.querySelector(".carouse-range__ar");
      if(step_element){
        step_element.innerHTML = data;
        if(step_element.classList.contains("d-none")){
          step_element.classList.remove("d-none");
        }
      }
      this.current_step = step;
      setTimeout(() => {
        step_element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        let submit_btn = document.getElementById(`successfully-submitted`);
        if(submit_btn){
          submit_btn.click();
        }
      }, 500);
    }
  }

  activateFormSubmission(event){
    let target = event.target;
    let step = parseInt(target.getAttribute("data-step"));
  
    if (isNaN(step)) {
      console.log("activateFormSubmission", target);
      console.log("step is NaN, exiting function.");
      return;
    }
    let request_type = "patch";
    let form = target.closest("form");
    if(step <= 1){
      request_type = "post";
    } 
    if (this.validateFields(step)){
      target.classList.add("d-none", "disabled");
      this.submitSellRequest(form, request_type);
    }
  }

  enableSubmitBtn(step){
    let submit_btn = this.main.querySelector(`.ar_submit_btn_${step}`);
    if(submit_btn){
      submit_btn.classList.remove("d-none", "disabled");
    }
  }

  validateFields(step){
    let fields = this.form_fields[step];
    let empty_fields = [];
    fields.forEach((field) => {
      let input = this.main.querySelector(`input[name="${field}"]`);
      let textarea = this.main.querySelector(`textarea[name="${field}"]`);
      let select = this.main.querySelector(`select[name="${field}"]`);

      if(input){
        let input_type = input.getAttribute("type");
        if(input_type === 'checkbox' && !input.checked){
          input.classList.add("is-invalid");
          empty_fields.push(input);
        }else if(!input.value){
          input.classList.add("is-invalid");
          empty_fields.push(input);
        }
      }else if (textarea){
        if(!textarea.value){
          textarea.classList.add("is-invalid");
          empty_fields.push(textarea);
        }
      }else if (select){
        if(!select.value){
          select.classList.add("is-invalid");
          empty_fields.push(select);
        }
      }
    });

    if(empty_fields.length > 0){
      empty_fields[0].scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
      return false;
    }else {
      return true;
    }
  }

  handleFileUpload(event){
    let index = event.target.getAttribute("data-index");
    let files = event.target.files;
    if(files && files.length > 0){
      for(let i = 0; i < files.length; i++){
        let file = files[i];
        let is_uploaded = this.checkIsFileAvailable(file);
        if(is_uploaded){
          this.showPopUpErrorInfo(`Tiedosto (${is_uploaded.name}) on jo ladattu.`)
        }else {
          this.photos.push(file)
          document.getElementById("ar_images").files = this.fileListItems(this.photos);
          this.showImagePreview(file, index)
        }
      }
    }
  }

  showImagePreview(file, index){
    let file_name = file.name;
    // get image_selection_preview_container with data-index
    let container = document.querySelector(`.image_selection_preview_container[data-index="${index}"]`);
    let url = this.getImageUrl(file);
    let html = this.previewAndRemoveHtml(url, index, file_name);
    container.innerHTML = html;
  }

  removeImagePreview(event){
    let index = event.target.getAttribute("data-index");
    let name = event.target.getAttribute("data-name");
    this.photos = this.photos.filter(({name: n}) => n !== name);
    document.getElementById("ar_images").files = this.fileListItems(this.photos);
    let container = document.querySelector(`.image_selection_preview_container[data-index="${index}"]`);
    container.innerHTML = this.selectionHtml(index);
  }

  checkIsFileAvailable(file){
    let file_name = file.name;
    return this.photos.find(({name}) => name === file_name)
  }
  fileListItems (files) {
    let b = new ClipboardEvent("").clipboardData || new DataTransfer()
    for (let i = 0, len = files.length; i<len; i++) b.items.add(files[i])
    return b.files
  }

  getImageUrl(file){
    let url = URL.createObjectURL(file)
    return url ? url : "";
  }

  showPopUpErrorInfo(message=""){
    let error_info = this.main.querySelector("#show-errors-info");
    if(error_info){
      let modal = document.querySelector(error_info.getAttribute("data-bs-target"));
      if(modal){
        let modal_body = modal.querySelector(".modal-body");
        if(modal_body){
          if(message){
            modal_body.innerHTML = message;
          }
          error_info.click();
        }
      }
    }
  }

  removeError(event){
    let target = event.target;
    if(target.classList.contains("is-invalid")){
      target.classList.remove("is-invalid");
    }
  }

  previewAndRemoveHtml(url, index, name=""){
    return `
        <img src="${url}" class="img-fluid rounded-3 pe-none" />
        <button data-name="${name}" data-index="${index}" data-action="autolle-carousel#removeImagePreview"
            class="btn btn-danger rounded-circle p-0 border-0 outline-none shadow-none box-size-20 position-absolute" style="top: -10px; right: -10px;">
          <i class="fas fa-times pe-none"></i>
        </button>
    `;
  }

  selectionHtml(index) {
    return `
      <label class="fs-14">
        <span class="btn bg-white text-primary border border-primary cursor-pointer rounded-pill px-3 d-flex align-items-center justify-content-center">
          <span class="d-block me-2"><i class="fas fa-plus"></i></span>
          <span class="d-block fs-12" style="white-space: nowrap">Valitse kuva</span>
        </span>
        <input type="file" class="d-none" data-index="${index}" data-action="change->autolle-carousel#handleFileUpload"/>
      </label>
    `;
  }
}
