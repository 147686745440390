// javascript/controllers/brands_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  min_height = 0;
  max_height = null;

  connect() {
    this.getHeroHeight();
    if (window.innerWidth < 992) {
      //this.applyMarqueeEffect();
    }
    this.marqueeContentScroll();
    this.adjustNoTitleBrands();
  }

  getHeroHeight() {
    const homeHeroElement = document.querySelector(".home_hero");
    if (homeHeroElement) {
      this.max_height = homeHeroElement.offsetHeight;
    }
  }

  toggleBrands(e) {
    let brand_icon = document.querySelector(".brands_up-down");
    if (brand_icon) {
      brand_icon.classList.toggle("up");
      brand_icon.classList.toggle("down");
      this.dropDownBgImage();
    }
  }

  dropDownBgImage() {
    const brandsBgElement = document.querySelector(".brands_bg");
    if (brandsBgElement) {
      const currentHeight = brandsBgElement.style.height;
      const newHeight =
        currentHeight === `${this.min_height}px`
          ? this.max_height
          : this.min_height;

      brandsBgElement.style.height = `${newHeight}px`;
      brandsBgElement.style.transition = "height 0.5s ease";
      this.showHideDropDownItems(newHeight);
      this.hideNavbar(newHeight);
    }
  }

  showHideDropDownItems(bgHeight) {
    const brandCardsElement = document.querySelector(".brands_cards-container");
    const brandsCards = document.querySelectorAll(".brands_card");

    if (brandCardsElement) {
      if (bgHeight > 0) {
        brandCardsElement.classList.add("d-block");
        brandCardsElement.classList.remove("d-none");
      } else {
        brandCardsElement.classList.remove("d-block");
        brandCardsElement.classList.add("d-none");
      }
      brandCardsElement.style.transition = "display 0.5s ease";
      brandsCards.forEach((card) => {
        if (bgHeight > 0) {
          setTimeout(() => {
            card.style.display = "block";
            card.style.opacity = "1";
            card.style.transition = "opacity 0.3s ease";
          }, 200);
        } else {
          card.style.display = "none";
          card.style.opacity = "0";
          card.style.transition = "opacity 0.1s ease";
        }
      });
    }
  }

  hideNavbar(elementHeight) {
    let navigation = document.querySelector(".navigation");

    if (navigation && elementHeight > 0) {
      navigation.classList.add("d-none");
    } else {
      navigation.classList.remove("d-none");
    }
  }

  applyMarqueeEffect() {
    const marqueeItem = document.querySelector(".marquee__item--last");
    const firstChild =
      document.querySelector(".marquee__item").firstElementChild;

    if (marqueeItem && firstChild) {
      firstChild.style.height = `${marqueeItem.offsetHeight}px`;
      firstChild.style.width = "10px";
      firstChild.style.backgroundColor = "red";
    }
  }

  marqueeContentScroll() {
    let marqueeContent = document.querySelector(".marquee__item");
    if(!marqueeContent){
      marqueeContent = document.querySelector(".marquee-full__item");
    }
    const marqueeItems = Array.from(marqueeContent.children);
    let totalWidth = 0;

    // Calculate total width of all items
    marqueeItems.forEach((item) => {
      totalWidth +=
          item.offsetWidth + parseInt(getComputedStyle(item).marginLeft);
    });
    
    if (totalWidth === 0) {
      return;
    }
    // Duplicate items until they fill the screen twice
    while (totalWidth < window.innerWidth * 2) {
      marqueeItems.forEach((item) => {
        const clone = item.cloneNode(true);
        marqueeContent.appendChild(clone);
        totalWidth +=
            clone.offsetWidth + parseInt(getComputedStyle(clone).marginLeft);
      });
    }

    marqueeContent.style.width = `${totalWidth}px`;
  }

  adjustNoTitleBrands(){
    let brandsNoTitle = document.querySelector('.brands-no-title');
    let navigationLight = document.querySelector('.navigation-light');

    // console.log("adjustNoTitleBrands", {brandsNoTitle, navigationLight})

    if (brandsNoTitle && navigationLight) {
      navigationLight.classList.add('adjusted');
    }
  }
}