// javascript/controllers/search_controller.js
import { Controller } from "stimulus";
import {
  addDarkTheme,
  addLightTheme,
  carPropertiesToFi,
  concatName,
  getData,
  removeFieldsFromObj,
  replaceClassName,
  replaceSomeSpecialChars,
} from "./../helpers";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    // Container that holds all the make checkbox inputs
    "makeContainer",
    // List of all filters that are available
    "filterNamesList",
    // Main search input
    "searchInput",
    // Searchhint parent container
    "searchResultList",
    // Searchhint main container
    "searchResultListItem",
    "clearSearchInputValue",
    // Ready made filters
    "readyFiltersContainer",
    // Make filter inputs
    "makeSearchResult",
    // Search result button
    "showResultBtn",
    "showResultBtnText",
    //Pagination
    "pagination",
    // Sorting
    "currentSortOption",
    // Engine Capacity
    "engineCapcity",
    // Container that holds all the filter elements
    "mainSearchFiltersContainer",
    // Main search bar
    "mainSearchBarSection",
  ];

  BASE_ROUTE = "ajoneuvot/get_cars";
  filter_data = null;
  cars_type = "in_stock";
  // Available search query values
  search_values = {
    page: [],
    order: [],
    make: [],
    model: [],
    body_type: [],
    price_max: [],
    price_min: [],
    monthly_max: [],
    monthly_min: [],
    year_max: [],
    year_min: [],
    mileage_max: [],
    mileage_min: [],
    seats_max: [],
    seats_min: [],
    engine_size: [],
    search: [],
    total_weight_max: [],
    total_weight_min: [],
    towing_brakes_max: [],
    towing_brakes_min: [],
    towing_no_brakes_max: [],
    towing_no_brakes_min: [],
    department_location: [],
  };

  // Current page
  page_context = null;

  // storage keys
  SEARCH_STORAGE_KEY = "@Tm+6x!Bns+SzNk7r+YC4[UX-W";
  CURRENT_PAGE_KEY = "uj/x/uywY34Q&.'?LZxkd&";
  HOME_PAGE_KEY = "+6Tww,H?zEwaj3%QB^ag)+_";
  LANDING_PAGE_KEY = "LANX2Rj3'B+NW,e=M;M";

  pathname = null;
  current_user = null;
  constructed_search_params = "";

  connect() {
    // Get Pathname
    this.pathname = location.pathname.split("/").filter(Boolean);
    // Focus on layout pages
    this.searchHintFocus();

    // Get current page name
    this.findCurrentPageName();

    // Determines if the page is rendering cars
    let cars_data_container = document.getElementById("cars-grid");

    // Fetch data only when car's container is available
    if (cars_data_container) {
      if (this.cars_type !== "landing") {
        this.fetchData();
      }
      this.page_context = "not homepage";
    } else {
      this.page_context = "homepage";
      this.saveSearchDataToStorage(this.HOME_PAGE_KEY, "homepage");
    }

    // Updates all the filters to previous state and shows result on initial
    // Update result button
    this.updateButtonSearchResult(null, "initial");
    // Get available filters
    this.get_filters_data();

    // Show search bar on specific pages
    this.hideSearchBarFromSpecificPage();
    this.scrollElementCarToView();
  }

  // Check matching route
  isRouteMatchingTo(name) {
    return this.pathname.indexOf(name) >= 0;
  }

  // Adding and Updating Cars Ad Car
  addUpdateCarsAdCorner() {
    let controller = this;
    setTimeout(() => {
      let main_element = document.getElementById("cars-left-corner-ad");
      let ad_elements = document.querySelectorAll(".cars-ad-element");
      if (main_element && ad_elements) {
        ad_elements.forEach((ele, index) => {
          let ele_id = ele.dataset.elementDetails;
          if (ele_id) {
            Rails.ajax({
              type: "get",
              url: `/api/elements/${ele_id}`,
              success: function (data) {
                let { ingredients } = data;
                if (main_element) {
                  let card_content = controller.printAdCard(ingredients);
                  if (card_content && index === 0) {
                    main_element.innerHTML = card_content;
                  } else if (card_content) {
                    main_element.insertAdjacentHTML(
                      "afterend",
                      `<div class="col-sm-12 col-md-6 col-lg-4 mb-3">${card_content}</div>`
                    );
                  }
                }
              },
            });
          }
        });
      }
    }, 1000);
  }

  printAdCard(ingredients) {
    if (!ingredients) {
      return;
    }
    let [img, url, heading, text, btn_name, color] = ingredients;
    return `<div class="w-100 full-height border border-1 rounded-3 p-2 position-relative d-flex flex-column zoom-out-bg min-height-350">
                <img src="${img.value}" class="position-absolute start-0 top-0 cover-image rounded-3 z-index-1" alt="${heading.value}" />
                <h5 class="fs-12 text-${color.value}">${heading.value}</h5>
                <h4 class="w-75 fw-normal fs-4 text-${color.value}">${text.value}</h4>
                <a class="btn btn-outline-dark bg-light text-dark align-self-start mt-auto" href="${url.value}">
                  ${btn_name.value}
                </a>
              </div>`;
  }

  // Resetting local storage data after page change
  resetStorageDataForNewPages() {
    let current_path = window.location.pathname;
    let single_car_page = current_path.split("/");
    let is_single_car_page =
      single_car_page.indexOf("ajoneuvot") > 0 ||
      single_car_page.indexOf("liisaa") > 0;
    let previous_home_page = this.getSearchDataFromStorage(this.HOME_PAGE_KEY);
    let previous_page_data = this.getSearchDataFromStorage(
      this.CURRENT_PAGE_KEY
    );
    if (previous_page_data) {
      if (
        previous_page_data !== current_path &&
        previous_home_page !== "homepage"
      ) {
        if (!is_single_car_page) {
          this.resetStorageDataAction("all");
        } else if (single_car_page.length <= 2) {
          this.resetStorageDataAction("all");
        }
      } else if (previous_home_page === "homepage") {
        // Set landing page value to empty
        this.resetStorageDataAction("homepage");
      }
    } else {
      this.saveSearchDataToStorage(this.CURRENT_PAGE_KEY, current_path);
    }
  }

  resetStorageDataAction(action_type = null) {
    if (action_type === "all") {
      this.saveSearchDataToStorage(this.SEARCH_STORAGE_KEY, {});
      this.saveSearchDataToStorage(
        this.CURRENT_PAGE_KEY,
        window.location.pathname
      );
      this.saveSearchDataToStorage(this.HOME_PAGE_KEY, "");
      // Set landing page value to empty
      this.saveSearchDataToStorage(this.LANDING_PAGE_KEY, "");
    } else if (action_type === "homepage") {
      this.saveSearchDataToStorage(this.LANDING_PAGE_KEY, "");
      this.saveSearchDataToStorage(this.HOME_PAGE_KEY, "");
      this.saveSearchDataToStorage(
        this.CURRENT_PAGE_KEY,
        window.location.pathname
      );
    }
  }

  // Find current page name
  findCurrentPageName() {
    this.resetStorageDataForNewPages();
    let page_element = document.getElementsByTagName("main")[0];
    let lease_page_name = "",
      landing_page_name = "",
      current_page_id = "",
      promotion_page = "",
      promotion_id = "";
    if (page_element) {
      lease_page_name = page_element.dataset.pageName;
      landing_page_name = page_element.dataset.landingPageName;
      current_page_id = page_element.dataset.pageDetails;
      promotion_page = page_element.dataset.promotionPage;
      promotion_id = page_element.dataset.promotionId;
      if (current_page_id) {
        this.search_values["page_details"] = current_page_id;
      }
      if (promotion_page && promotion_page === "yes" && promotion_id) {
        this.search_values["promotion_id"] = promotion_id;
      }
    }

    if (lease_page_name && lease_page_name === "lease") {
      this.BASE_ROUTE = "ajoneuvot/get_leasing_cars";
      this.cars_type = "lease";
      let huoleton_page = document.getElementById("huoleton-page");
      if (huoleton_page) {
        document
          .getElementById("main-search-bar-section")
          .classList.remove("shadow");
      }
    } else if (landing_page_name) {
      let params = "";
      this.BASE_ROUTE = "ajoneuvot/get_landing_cars";
      this.cars_type = "landing";
      let landing_page_type = page_element.dataset.landingPageType;
      let initialLoad = page_element.dataset.initialLoad;
      let landing_page_key = this.getSearchDataFromStorage(
        this.LANDING_PAGE_KEY
      );
      if (!landing_page_key) {
        initialLoad = "initial";
      }
      this.search_values["page_name"] = landing_page_name;
      this.search_values["page_type"] = landing_page_type;
      if (current_page_id) {
        this.search_values["page_details"] = current_page_id;
      }
      params = `page_name=${landing_page_name}&page_type=${landing_page_type}`;
      if (promotion_page && promotion_page === "yes" && promotion_id) {
        this.search_values["promotion_id"] = promotion_id;
        params += `&promotion_id=${promotion_id}`;
      }
      page_element.dataset.initialLoad = "update";

      if (initialLoad === "initial") {
        let previous_state = this.getSearchDataFromStorage(
          this.LANDING_PAGE_KEY
        );

        if (previous_state) {
          this.fetchData();
        } else {
          this.getAvailableLandingPagesFilters(params);
          this.saveSearchDataToStorage(this.LANDING_PAGE_KEY, "update state");
        }
      } else {
        this.fetchData();
      }
    }
  }

  // Getting available filters for current page and update locally
  getAvailableLandingPagesFilters(params) {
    let controller = this;
    let final_query = "";
    Rails.ajax({
      type: "get",
      url: `/ajoneuvot/get_landing_filters?${params}`,
      success: function (response) {
        let { data } = response;
        if (data) {
          // Query string to object
          let query_object = controller.getSearchDataFromUrl(data);
          if (query_object["step"]) {
            delete query_object["step"];
          }

          controller.fetchData(query_object);
          controller.updateExistingSearchQuery();
        } else {
          controller.fetchData();
          controller.updateExistingSearchQuery();
        }
      },
    });
  }

  // Fetches data to update car's cards
  fetchData(query_params = null) {
    let controller = this;
    let params_url_string = "";
    let storage_data = this.getSearchDataFromStorage(this.SEARCH_STORAGE_KEY);
    let urlParams = this.getSearchDataFromUrl();
    if (query_params && Object.keys(query_params).length > 0) {
      params_url_string = this.constructSearchQuery(query_params);
    } else {
      if (storage_data && Object.keys(storage_data).length > 0) {
        params_url_string = this.constructSearchQuery(storage_data);
      } else if (urlParams && Object.keys(urlParams).length > 0) {
        urlParams["page_details"] = this.search_values["page_details"];
        params_url_string = this.constructSearchQuery(urlParams);
      } else {
        params_url_string = `page_details=${this.search_values["page_details"]}`;
      }
    }

    Rails.ajax({
      type: "get",
      url: `/${this.BASE_ROUTE}?${params_url_string ? params_url_string : ""}`,
      success: function (data) {
        if (controller.cars_type == "landing") {
          controller.updateExistingSearchQuery();
        }
        // Create Observer
        //setTimeout(() => {controller.createObserver();},500)
      },
    });
  }

  clearFilter(event) {
    if (this.cars_type === "landing") {
      this.fetchData();
    } else {
      // Reset search values
      this.resetSearchValues(this.search_values);
      // Update
      this.updateButtonSearchResult(this.search_values);
      if (event.target.dataset.sliderBtn === "Yes") {
        location.reload();
      }
    }
  }

  resetSearchValues(values) {
    return Object.keys(values).map((key) => {
      if (key !== "page" && key !== "order") {
        this.search_values[key] = [];
      }
    });
  }

  // Toggle Exact search window
  toggleExactSearch() {
    let element = document.getElementById("main-search__filters");
    this.toggleElementDisplay(element);
  }

  // Toggle display property
  toggleElementDisplay(element) {
    element.classList.remove("invisible");
    if (element.classList.contains("d-none")) {
      element.classList.remove("d-none");
      element.classList.add("d-block");
    } else if (element.classList.contains("d-block")) {
      element.classList.remove("d-block");
      element.classList.add("d-none");
    }
  }

  hideMainDrawer() {
    let element = document.getElementById("main-search__filters");
    if (element.classList.contains("d-block")) {
      element.classList.remove("d-block");
      element.classList.add("d-none");
    }
  }

  // Clears main search input field
  clearSearchInput() {
    let input = this.searchInputTarget;
    let result_list = this.searchResultListTarget;
    let clear_values_btn = this.clearSearchInputValueTarget;
    input.value = "";
    this.toggleElementDisplay(result_list);
    this.toggleElementDisplay(clear_values_btn);
    //this.readyFiltersContainerTarget.classList.add("height-0");
    // Using id instead of target (same element)
    let ready_filter_container = document.getElementById(
      "ready-filters-container"
    );
    let main_search_bar = document.getElementById("main-search-bar-section");
    if (this.page_context === "homepage") {
      this.updateHomeNavigationTheme("light");
      if (main_search_bar.classList.contains("bg-white")) {
        main_search_bar.classList.add("bg-transparent");
        main_search_bar.classList.remove("shadow");
        main_search_bar.classList.remove("bg-white");
        ready_filter_container.classList.add("height-0");
      }
    } else {
      if (ready_filter_container) {
        ready_filter_container.classList.add("height-0");
      }
    }
  }

  // Handles user inputs from the main search input
  searchHintInput() {
    let input = this.searchInputTarget;
    let result_list = this.searchResultListTarget;
    let clear_values_btn = this.clearSearchInputValueTarget;

    if (input.value) {
      result_list.classList.remove("d-none");
      result_list.classList.add("d-block");
      clear_values_btn.classList.remove("d-none");
      clear_values_btn.classList.add("d-block");
      this.showHintSearchResult(input.value);
    } else {
      result_list.classList.remove("d-block");
      result_list.classList.add("d-none");
      clear_values_btn.classList.remove("d-block");
      clear_values_btn.classList.add("d-none");
    }
  }

  // Shows ready filter container
  searchHintFocus() {
    let main = document.getElementsByTagName("main")[0];
    if (main) {
      let layout_name = main.dataset.layoutName;
      if (layout_name === "buy") {
        this.updateHomeNavigationTheme("dark");
        let container = document.getElementById("frontpage-contact-container");
        if (container) {
          container.classList.add("d-none");
        }
      }
    }
  }

  // update theme
  updateHomeNavigationTheme(theme) {
    let nav = document.getElementById("navigation");
    let search_bar = this.mainSearchBarSectionTarget;
    //let container = this.readyFiltersContainerTarget;
    // Using id instead of target (same element)
    let container = document.getElementById("ready-filters-container");
    let sub_container = document.getElementById("ready-filters-sub-container");
    if (theme === "dark") {
      if (nav) {
        addDarkTheme(nav);
      }
      container.classList.remove("height-0");
      search_bar.classList.remove("bg-transparent");
      search_bar.classList.add("shadow");
      search_bar.classList.add("bg-white");
      if (sub_container) {
        sub_container.classList.remove("d-none");
      }
    }

    if (theme === "light") {
      if (nav) {
        addLightTheme(nav);
      }
      if (sub_container) {
        sub_container.classList.add("d-none");
      }

      container.classList.add("height-0");
      search_bar.classList.add("bg-transparent");
      search_bar.classList.remove("shadow");
      search_bar.classList.remove("bg-white");
    }
  }

  // Gets available make and model by name
  getMakeModelList(obj, value) {
    let pattern = new RegExp("^" + value, "i");
    let filtered_data = {};
    let make_tracker = [];
    let model_tracker = [];
    for (let key in obj) {
      let found_models = [];
      for (let val of obj[key]) {
        let make_model = key + " " + val.name;
        if (
          pattern.test(key.toLowerCase()) ||
          pattern.test(val.name.toLowerCase()) ||
          pattern.test(make_model.toLowerCase())
        ) {
          if (!make_tracker.includes(key)) {
            make_tracker.push(key);
            //filtered_data[key] = obj[key]
          }
          if (!model_tracker.includes(val.name)) {
            model_tracker.push(val.name);
            found_models.push(val);
          }
        }
      }
      filtered_data[key] = found_models;
    }

    return this.sortItemsDescending(filtered_data);
  }

  // Shows hints that match user input
  showHintSearchResult(value) {
    let list = this.searchResultListItemTarget;
    let list_items = list.getElementsByTagName("li");
    let no_search_item = document.getElementById("no-result-item");
    let pattern = new RegExp("^" + value, "i");
    let available_items = [];
    Array.from(list_items).forEach((item, index) => {
      let item_make = item.dataset.hintMake;
      let item_model = item.dataset.hintModel;
      let item_make_model = `${item_make} ${item_model}`;

      if (
        pattern.test(item_make.toLowerCase()) ||
        pattern.test(item_model.toLowerCase()) ||
        pattern.test(item_make_model.toLowerCase())
      ) {
        if (item.classList.contains("d-none")) {
          item.classList.remove("d-none");
        }
        available_items.push(index);
      } else {
        if (item.classList.contains("d-block")) {
          item.classList.remove("d-block");
        }
        item.classList.add("d-none");
      }
    });

    if (available_items.length <= 0) {
      no_search_item.classList.remove("d-none");
    }
  }

  // Sorts object descending
  sortItemsDescending(data) {
    return Object.keys(data)
      .sort()
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});
  }

  // Show models (sublist of the make inputs) of the selected Car make
  selectedMake(event) {
    let current_input = event.target;
    let current_input_name = event.target.dataset.makeName;

    this.hideShowSelectedMakeCheckboxes(current_input, current_input_name);
  }

  removeUnSelectedMakeModels(make) {
    let models = this.filter_data[make];
    if (models.length > 0) {
      models.map((model) => {
        let model_chekcbox = document.getElementById(
          concatName(model.name) + "-model-checkbox"
        );
        if (model_chekcbox) {
          model_chekcbox.checked = false;
        }
      });
    }
  }

  hideShowSelectedMakeCheckboxes(target_element, name) {
    let element_id = concatName(name);
    let make_model_list = document.getElementById(`${element_id}-list`);
    let make_model_number = document.getElementById(`${element_id}-number`);
    let make_model_arrows = document.getElementById(`${element_id}-arrows`);
    let make_model_icon = document.getElementById(`${element_id}-icon`);

    if (target_element.checked) {
      replaceClassName(make_model_list, "d-none", "d-block");
      replaceClassName(make_model_number, "d-block", "d-none");
      replaceClassName(make_model_arrows, "d-none", "d-block");
    } else {
      this.removeUnSelectedMakeModels(name);
      replaceClassName(make_model_list, "d-block", "d-none");
      replaceClassName(make_model_number, "d-none", "d-block");
      replaceClassName(make_model_arrows, "d-block", "d-none");
    }

    this.getSelectedMakeAndModel();
  }

  // Tracks selected model
  selectedModel(event) {
    let current_input = event.target;
    let current_input_name = event.target.dataset.modelName;

    this.hideShowSelectedModelsCheckboxes(current_input_name);
  }

  hideShowSelectedModelsCheckboxes(name) {
    let make_model_list = document.getElementById(
      concatName(name) + "-model-checkbox"
    );

    this.getSelectedMakeAndModel();
  }

  // Filters Car make and model that match user's input value
  makeSearchInput(event) {
    let value = event.target.value;
    const make_model_data = { ...this.filter_data };
    const found_items = this.getMakeModelList(make_model_data, value);
    let all_items = Object.keys(make_model_data);

    let filtered_found_items = Object.keys(found_items).filter(
      (item) => found_items[item].length !== 0
    );

    if (value && filtered_found_items.length > 0) {
      let hidden_items = all_items.filter(
        (item) => filtered_found_items.indexOf(item) < 0
      );

      hidden_items.forEach((item) => {
        let name = concatName(item) + "-main";
        document.getElementById(name).classList.add("d-none");
      });
    } else {
      all_items.forEach((item) => {
        let name = concatName(item) + "-main";
        document.getElementById(name).classList.remove("d-none");
      });
    }
  }

  // Gets values of all selected checkboxes
  getSelectedMakeAndModel() {
    let container = this.makeContainerTarget;
    let checkboxes = container.querySelectorAll("input:checked");

    let makes = [];
    let models = [];
    checkboxes.forEach((ch) => {
      if (ch.dataset.makeType === "make") {
        makes.push(ch.dataset.makeName);
      }

      if (ch.dataset.makeType === "model") {
        models.push(ch.dataset.modelName);
      }
    });

    if (
      JSON.stringify(this.search_values["make"]) != JSON.stringify(makes) ||
      JSON.stringify(this.search_values["model"]) != JSON.stringify(models)
    ) {
      this.search_values["make"] = makes;
      this.search_values["model"] = models;
      this.updateButtonSearchResult(this.search_values);
    }
  }

  // Updating the search result number
  updateButtonSearchResult(search_obj, context = null) {
    let search_terms = "";
    let storage_data = this.getSearchDataFromStorage(this.SEARCH_STORAGE_KEY);
    if (search_obj === null && context === "initial") {
      if (this.page_context !== "homepage") {
        this.updateExistingSearchQuery();
      }
      if (storage_data) {
        search_terms = this.constructSearchQuery(storage_data);
      } else {
        search_terms = this.constructSearchQuery(this.search_values);
      }
    } else {
      search_terms = this.constructSearchQuery(search_obj);
    }

    // Preparing landing params
    let landing_params = "";
    let { page_name, page_type } = this.search_values;
    if (this.cars_type === "landing" && page_name && page_type) {
      let promotion_id = this.search_values.promotion_id;
      let is_admin = this.isRouteMatchingTo("admin") ? "1" : "0";
      let seperator = search_terms ? "&" : "";
      landing_params = `${seperator}step=2&admin=${is_admin}`;
      if (promotion_id) {
        landing_params += `&promotion_id=${promotion_id}`;
      }
      let main_search_result_container = document.querySelector(
        ".main-search__result"
      );
      if (main_search_result_container) {
        main_search_result_container.style.paddingTop = "1.25rem";
      }
    }

    //Updataing search button href attribute;
    let show_result_btn_href = document.getElementById("show-result-btn");
    if (show_result_btn_href) {
      show_result_btn_href.href = `/${this.BASE_ROUTE}?${search_terms}${landing_params}`;
      this.constructed_search_params = `?${search_terms}${landing_params}`;
    }

    // Showing selected parameters on the drawer
    this.displayAvailableSearchParams(this.search_values);

    // Showing selected filter params buttons
    if (this.page_context !== "homepage") {
      this.showUpdateSearchParameters(this.search_values);
    }

    // Getting and updating cars
    this.getOrUpdateData(search_terms, context);
  }

  // Getting and updating data according to search params
  getOrUpdateData(search_terms, context = null) {
    getData(`/${this.BASE_ROUTE}.json?${search_terms}`)
      .then((response) => {
        const {
          status,
          pagy,
          body_type,
          fuel,
          gear,
          drive_type,
          base_type,
          filtered_make_model,
          user,
        } = response;
        const { count } = pagy;
        this.current_user = user;
        // Tracking different properties of Car makes
        // Example how many are 'Farmari' for selected Car makes
        //this.updateBodyTypeCount(body_type);
        //this.updateBodyTypeCount(fuel);
        //this.updateBodyTypeCount(gear);
        //this.updateBodyTypeCount(drive_type);
        //this.updateBodyTypeCount(base_type);
        //this.updateMakeModelCount(filtered_make_model);

        if (status === "SUCCESS") {
          let word_action = "Näytä";
          if (this.cars_type === "landing" && this.isRouteMatchingTo("admin")) {
            word_action = "Tallenna";
          }
          let show_result_btn_text = document.getElementById(
            "show-result-btn-text"
          );
          let show_result_btn_href = document.getElementById("show-result-btn");
          if (show_result_btn_href) {
            show_result_btn_text.innerHTML = `${word_action} ${count} tulosta`;
          }
          let total_vehicles = document.getElementById("total-vehicles");
          if (total_vehicles) {
            total_vehicles.textContent = count;
          }
          if (context && context === "hint") {
            if (this.page_context === "homepage") {
              if (show_result_btn_href) {
                show_result_btn_href.click();
              }
            } else {
              location.reload();
            }
          }
        }
      })
      .catch((error) => console.log("error", error));
  }

  updateSearchFromHintList(event) {
    let list_item = event.target;
    let list_item_make = list_item.dataset.hintMake;
    let list_item_model = list_item.dataset.hintModel;

    let query_params = { ...this.search_values };

    query_params["make"] = [list_item_make];
    query_params["model"] = [list_item_model];

    // Resetting all other property except make and model
    let params = Object.keys(query_params)
      .filter((param) => param != "make" && param != "model")
      .forEach((val) => {
        query_params[val] = [];
      });

    query_params["order"] = ["price"];
    query_params["page"] = [1];

    this.fetchData(query_params);
    this.updateButtonSearchResult(query_params, "hint");
  }

  // Constracting the search query
  constructSearchQuery(search_terms, saving_action = null) {
    let terms = Object.keys(search_terms);
    if (terms.length === 0) {
      return;
    }

    let url_obj = {};
    let search_array = [];
    terms.forEach((val) => {
      if (search_terms && typeof search_terms[val] === "string") {
        search_terms[val] = search_terms[val].split(",");
      } else if (search_terms && typeof search_terms[val] === "number") {
        search_terms[val] = [search_terms[val]];
      }

      if (search_terms[val] && search_terms[val].length > 0) {
        let joined_vals = search_terms[val].join(",");
        if (joined_vals) {
          let search_string = val + "=" + joined_vals;
          search_array.push(search_string);
          url_obj[val] = `${joined_vals}`;
        }
      }
    });

    if (!saving_action) {
      this.saveSearchDataToStorage(this.SEARCH_STORAGE_KEY, url_obj);
    }
    return search_array.join("&");
  }

  // Adding browser history to search params
  updateBrowserUrlHistory(url_obj) {
    let url_keys = Object.keys(url_obj);
    let url_name = location.href;
    let current_url = new URL(url_name);
    url_keys.forEach((key) => {
      current_url.searchParams.set(key, url_obj[key]);
    });
    let url_string = decodeURIComponent(current_url);
    window.history.pushState({}, "", url_string);
  }

  // Show result when clicked show button
  showFiltersResult(event) {
    let car_promotions_main = document.getElementById("car-promotions-main");
    if (car_promotions_main) {
      event.preventDefault();
      event.target.dataset.remote = false;
      this.updateCarPromotions();
      this.toggleExactSearch();
      return;
    }
    //this.toggleExactSearch();
    this.hideMainDrawer();
    //location.href = document.getElementById("show-result-btn").href;
    if (this.page_context === "homepage") {
      let main_hero_header = document.getElementById("main-hero-header");
      if (main_hero_header) {
        let redirect_page = main_hero_header.dataset.nextPage;
        if (redirect_page) {
          location.href = `/${redirect_page}`;
        }
      } else {
        location.href = "/osta-auto";
      }
    } else {
      document.getElementById("cars-grid").scrollIntoView();
      // Create Observer
      //setTimeout(() => {this.createObserver();},500)
    }
  }

  refreshPage() {
    if (this.page_context === "homepage") {
      location.href = "/osta";
    } else {
      window.location.reload();
    }
  }

  // Saving search data to local storage
  saveSearchDataToStorage(name, data) {
    if (name === this.SEARCH_STORAGE_KEY) {
      let page_element = document.getElementsByTagName("main")[0];
      if (page_element) {
        let current_page_id = page_element.dataset.pageDetails;
        if (current_page_id && !data["page_details"]) {
          data["page_details"] = current_page_id;
        }
      }
    }
    sessionStorage.setItem(name, JSON.stringify(data));
  }

  // Retrieving search data from localstorage
  getSearchDataFromStorage(name) {
    let search_data = JSON.parse(sessionStorage.getItem(name));

    if (search_data) {
      return search_data;
    }

    return null;
  }

  // Getting search params from url
  getSearchDataFromUrl(search_string = null) {
    const query_string = search_string ? search_string : window.location.search;
    const url_params = new URLSearchParams(query_string);
    const entries = url_params.entries();
    let data_obj = {};

    for (const entry of entries) {
      let k = entry[0];
      if(!k.startsWith("utm_")) {
        data_obj[k] = entry[1];
        console.log("...");
      }
    }

    console.log(".....");

    return data_obj;
  }

  // Updating previous selected items
  // After refresh or opening the link in another browser
  updateExistingSearchQuery() {
    let storage_data = this.getSearchDataFromStorage(this.SEARCH_STORAGE_KEY);
    let url_data = this.getSearchDataFromUrl();
    let is_url_data_size = Object.keys(url_data).length > 0;

    let is_storage_data_size =
      storage_data && Object.keys(storage_data).length > 0;

    let search_query = {};
    if (is_url_data_size || is_storage_data_size) {
      if (is_url_data_size) {
        search_query = url_data;
      } else if (is_storage_data_size) {
        search_query = storage_data;
      }
    }
    // Update search filter inputs for example checking "Audi" checkbox if selected
    this.updateSearchFilterInputs(search_query);
    // Construct query => "?page=1&order=-price"
    //this.constructSearchQuery(search_query);
    // Change current sorted option => "Uusin ensin"
    this.displayCurrentSortedOption(search_query["order"]);
    // Updated shared items (Korimalli etc)
    this.updateSharedCheckboxes(search_query);
    // Update shared number inputs such as price, monthly payment etc
    this.updateSharedNumberInputValues(search_query);
    // Update switch state
    this.updateSwitchFiltersState(search_query["search"]);
    // Update engine size
    this.updateEngineSizeCheckboxes(search_query["engine_size"]);
  }

  // Updates make and model inputs (checkboxes)
  updateSearchFilterInputs(search_query) {
    if (!search_query) {
      return;
    }
    let container = this.makeContainerTarget;
    let checkboxes = container.querySelectorAll("input");

    let available_make = this.createMakeModelInputId(
      search_query["make"],
      "-checkbox"
    );

    let available_make_models_list = this.createMakeModelInputId(
      search_query["make"],
      ""
    );
    let available_model = this.createMakeModelInputId(
      search_query["model"],
      "-model-checkbox"
    );

    let available_model_active = this.createMakeModelInputId(
      search_query["model"],
      ""
    );

    checkboxes.forEach((ch) => {
      if (
        available_make &&
        available_make.length > 0 &&
        available_make.includes(ch.id)
      ) {
        ch.checked = true;
        // Remove checked item, otherwise it will be checked again
        available_make = this.removeCurrentItemFromList(available_make, ch.id);
        available_make_models_list.forEach((list_item) => {
          this.hideShowSelectedMakeCheckboxes(ch, list_item);
        });
      }

      if (
        available_model &&
        available_model.length > 0 &&
        available_model.includes(ch.id)
      ) {
        ch.checked = true;
        available_model_active.forEach((car_model) => {
          this.hideShowSelectedModelsCheckboxes(car_model);
        });
        // Remove checked item, otherwise it will be checked again
        available_model = this.removeCurrentItemFromList(
          available_model,
          ch.id
        );
      }
    });
  }

  createMakeModelInputId(name, concat_string) {
    let available_name = name;
    let available_name_array = null;
    if (available_name) {
      available_name_array = available_name
        .split(",")
        .map((st) => `${concatName(st)}${concat_string}`);
    }

    return available_name_array;
  }

  removeCurrentItemFromList(items, name) {
    return items.filter((it) => it !== name);
  }

  // Initial loading, renders default elements and values
  get_filters_data() {
    let landing_pg_type = "";
    let { page_name, page_type } = this.search_values;
    if (this.cars_type === "landing" && page_name && page_type) {
      landing_pg_type = page_type;
    } else {
      landing_pg_type = this.cars_type;
    }
    getData(`/ajoneuvot/get_filters?type=${landing_pg_type}`)
      .then((response) => {
        const { status, data, total } = response;
        if (status === "SUCCESS") {
          this.filter_data = data;
        }
      })
      .catch((error) => console.log("error", error));
  }

  toggleFilterAction(event) {
    let mainList = this.filterNamesListTarget;
    let mainList_btns = mainList.querySelectorAll("button");
    let current_filter_id = event.target.dataset.filterId;
    let sublist_parent = document.getElementById(current_filter_id);
    let back_action = event.target.dataset.back;
    let toggle_action = back_action === "back" ? "hide" : "show";
    this.toggleSublist(mainList, sublist_parent, toggle_action);
  }

  toggleSublist(mainList, subList, action) {
    if (action === "show") {
      if (mainList && subList) {
        mainList.classList.add("d-none");
        subList.classList.remove("start-100");
        subList.classList.remove("d-none");
        subList.classList.remove("invisible");
        subList.classList.add("d-block");
        subList.classList.add("start-0");
        subList.classList.add("transition-2s");
      }
    }

    if (action === "hide") {
      if (mainList && subList) {
        mainList.classList.remove("d-none");
        subList.classList.add("start-100");
        subList.classList.add("d-none");
        subList.classList.add("invisible");
        subList.classList.remove("d-block");
        subList.classList.remove("start-0");
        subList.classList.add("transition-2s");
      }
    }
  }

  //Sorting
  sortingOption(name) {
    let sorting_columns = {
      "edullisin ensin": "price",
      "kallein ensin": "-price",
      "uusin ensin": "latest",
      "vanhin ensin": "oldest",
      "vanhin vuosimalli ensin": "year",
      "uusin vuosimalli ensin": "-year",
    };

    return sorting_columns[name] ? sorting_columns[name] : "latest";
  }

  displayCurrentSortedOption(name) {
    let sorting_columns = {
      price: "Edullisin ensin",
      "-price": "Kallein ensin",
      latest: "Uusin ensin",
      oldest: "Vanhin ensin",
      year: "Vanhin vuosimalli ensin",
      "-year": "Uusin vuosimalli ensin",
    };
    let current_option = sorting_columns[name]
      ? sorting_columns[name]
      : sorting_columns["latest"];
    this.search_values["order"] = name;
    let current_sort_option = document.getElementById("current-sort-option");
    if (current_sort_option) {
      current_sort_option.textContent = current_option;
    }
  }

  sortCarsData(event) {
    let controller = this;
    let name = event.target.textContent;
    let selected = this.sortingOption(name.toLowerCase());
    //let search_terms = this.getSearchDataFromUrl();
    let search_terms = this.getSearchDataFromStorage(this.SEARCH_STORAGE_KEY);
    search_terms["order"] = [selected];
    let search_string = this.constructSearchQuery(search_terms);
    this.displayCurrentSortedOption(search_terms["order"]);

    Rails.ajax({
      type: "get",
      url: `/${this.BASE_ROUTE}?${search_string}`,
      success: function () {
        console.log;
        // Create Observer
        //setTimeout(() => {controller.createObserver();},500)
      },
    });

    //window.location.href = `/${this.BASE_ROUTE}?${search_string}`;
  }

  // Shared Section
  sharedSelectedCheckbox(event) {
    let value = event.target.dataset.typeName;
    let input_type = event.target.dataset.inputType;
    if (
      input_type === "body_type" ||
      input_type === "fuel" ||
      input_type === "gear" ||
      input_type === "drive_type" ||
      input_type === "base_type" ||
      input_type === "department_location"
    ) {
      this.toggleSharedCheckboxes(input_type);
    }
  }

  // Shared searching text input
  sharedSearchInput(event) {
    let target_type = event.target.dataset.filterName;
    let value = event.target.value;
    if (target_type === "body_type") {
      this.toggleVisibilityOfInputs("body_type", value);
    }
  }

  // Shows and hides according to the match of the input value
  toggleVisibilityOfInputs(name, value) {
    let list = document.getElementById(`${name}_search_result`);
    let list_items = list.getElementsByTagName("li");
    let pattern = new RegExp("^" + value, "i");
    Array.from(list_items).forEach((item) => {
      let item_value = item.dataset.typeName.toLowerCase();
      if (pattern.test(item_value)) {
        if (item.classList.contains("d-none")) {
          item.classList.remove("d-none");
        }
        item.classList.add("d-block");
      } else {
        if (item.classList.contains("d-block")) {
          item.classList.remove("block");
        }
        item.classList.add("d-none");
      }
    });
  }

  // Get the checkboxes that have been checked
  toggleSharedCheckboxes(name) {
    let list = document.getElementById(`${name}_search_result`);
    let checkboxes = list.querySelectorAll("input:checked");
    let input_values = [];
    checkboxes.forEach((ch) => {
      input_values.push(ch.dataset.typeName);
    });
    if (
      JSON.stringify(this.search_values[name]) != JSON.stringify(input_values)
    ) {
      this.search_values[name] = input_values;
      this.updateButtonSearchResult(this.search_values);
    }
  }

  updateSharedCheckboxes(search_query) {
    if (!search_query) {
      return;
    }
    let all_types = [
      "body_type",
      "fuel",
      "gear",
      "drive_type",
      "base_type",
      "department_location",
    ];
    let search_query_keys = Object.keys(search_query).filter(
      (key) => all_types.indexOf(key) !== -1
    );

    if (!search_query_keys.length) {
      return;
    }

    search_query_keys.forEach((key) => {
      this.updateselectedCheckboxes(key, search_query[key]);
    });
  }

  updateselectedCheckboxes(name, value) {
    let values = [];
    if (typeof value === "string") {
      values = value.split(",");
    } else {
      values = value;
    }
    let list = document.getElementById(`${name}_search_result`);
    let checkboxes = list.querySelectorAll("input");
    checkboxes.forEach((ch, index) => {
      if (ch.id) {
        values.forEach((val) => {
          let replace_val = replaceSomeSpecialChars(val);
          let found_input = replace_val + "-checkbox";
          let found_input_count = document.getElementById(
            replace_val + "-count"
          );
          if (found_input === ch.id) {
            ch.checked = true;
          }
        });
      }
    });

    this.search_values[name] = values;
    this.updateButtonSearchResult(this.search_values);
  }

  updateBodyTypeCount(make_model_filters) {
    const { filtered, unfiltered } = make_model_filters;

    let filtered_data = this.createReadableKeys(filtered);
    let un_filtered_data = this.createReadableKeys(unfiltered);
    let filtered_keys = [];
    filtered_data.forEach((filter_obj) => {
      let [filter_obj_name] = Object.keys(filter_obj);
      filtered_keys.push(filter_obj_name);
    });

    un_filtered_data.forEach((data, index) => {
      let [name] = Object.keys(data);
      let count_element = document.getElementById(name + "-count");
      if (count_element) {
        if (filtered_keys.indexOf(name) >= 0) {
          filtered_data.forEach((filter_obj) => {
            let [filter_obj_name] = Object.keys(filter_obj);
            if (name === filter_obj_name) {
              count_element.textContent = filter_obj[filter_obj_name];
            }
          });
        } else {
          count_element.textContent = 0;
        }
      }
    });
  }

  updateMakeModelCount(count_filters) {
    const { filtered, unfiltered } = count_filters || {
      filtered: [],
      unfiltered: [],
    };

    let available_make = [],
      available_model = [];
    filtered.forEach((fd) => {
      let { make_count, make_count_id, make_id } = fd;
      let make_count_element = document.getElementById(make_count_id);
      if (make_count_element) {
        make_count_element.textContent = make_count;
        available_make.push(make_id);
      }

      fd.models.forEach((fdm) => {
        let { model_id, model_count_id, model_count } = fdm;
        let model_count_element = document.getElementById(model_count_id);
        if (model_count_element) {
          model_count_element.textContent = model_count;
          available_model.push(model_id);
        }
      });
    });

    unfiltered.forEach((ufd, index) => {
      let { make_count, make_count_id, make_id } = ufd;
      let make_element = document.getElementById(make_id);
      let make_count_element = document.getElementById(make_count_id);
      make_element.style.backgroundColor = "";
      if (available_make.indexOf(make_id) >= 0) {
        //make_element.disabled = false;
        //make_element.style.backgroundColor = "green";
      } else {
        make_element.style.backgroundColor = "lightgray";
        //make_element.disabled = true;
        make_count_element.textContent = 0;
      }

      ufd.models.forEach((fdm) => {
        let { model_id, model_count_id, model_count } = fdm;
        let model_element = document.getElementById(model_id);
        let model_count_element = document.getElementById(model_count_id);
        if (available_model.indexOf(model_id) < 0) {
          //model_element.disabled = true;
          if (model_count_element) {
            model_count_element.textContent = 0;
          }
        } else {
          //model_element.disabled = false;
        }
      });
    });
  }

  createReadableKeys(data) {
    if (!data) {
      data = {};
    }
    return Object.keys(data).map((key) => {
      let new_key = replaceSomeSpecialChars(key);
      let obj = {};
      obj[new_key] = data[key];
      return obj;
    });
  }

  // Shared min - max inputs such as price, monthly payment etc
  sharedNumberInputAction(event) {
    let target = event.target;
    let value = target.value;
    let filter_name = target.dataset.filterName;
    // Checking if target is min or max
    if (filter_name) {
      this.search_values[filter_name][0] = value;
    }

    if (this.search_values[filter_name].length > 0) {
      this.updateButtonSearchResult(this.search_values);
    }
  }

  updateSharedNumberInputValues(search_query) {
    if (!search_query) {
      return;
    }

    let all_types = [
      "price_min",
      "price_max",
      "monthly_min",
      "monthly_max",
      "year_min",
      "year_max",
      "mileage_min",
      "mileage_max",
      "seats_min",
      "seats_max",
      "total_weight_max",
      "total_weight_min",
      "towing_brakes_max",
      "towing_brakes_min",
      "towing_no_brakes_max",
      "towing_no_brakes_min",
    ];

    let search_query_keys = Object.keys(search_query).filter(
      (key) => all_types.indexOf(key) !== -1
    );

    if (!search_query_keys.length) {
      return;
    }

    search_query_keys.forEach((key) => {
      this.updateCurrentSharedNumberInputs(key, search_query[key]);
    });
  }

  updateCurrentSharedNumberInputs(name, value) {
    let range = [];
    if (typeof value === "string") {
      range = value.split(",");
    } else {
      range = value;
    }

    let target = document.getElementById(name);

    if (range.length > 0) {
      target.value = range[0];
    } else {
      target.value = 0;
    }

    this.search_values[name] = range;
    this.updateButtonSearchResult(this.search_values);
  }

  // Switches
  // Toggle switch
  toggleFilterSwitch(event) {
    let target = event.target;
    let name = target.dataset.filterName;
    let current_switch_values = this.search_values["search"];
    if (!current_switch_values && typeof "string") {
      current_switch_values = current_switch_values.split(",");
    }

    if (target.checked) {
      current_switch_values.push(name);
    } else {
      current_switch_values = current_switch_values.filter((sw) => sw !== name);
    }

    this.search_values["search"] = current_switch_values;
    this.updateButtonSearchResult(this.search_values);
  }

  // Update after reload
  updateSwitchFiltersState(switch_values) {
    if (!switch_values) {
      return;
    }

    if (typeof switch_values === "string") {
      switch_values = switch_values.split(",");
    }

    switch_values.forEach((sw) => {
      let target = document.getElementById(`${sw}-switch`);
      target.checked = true;
    });

    this.search_values["search"] = switch_values;
    this.updateButtonSearchResult(this.search_values);
  }

  // Hardcoded values
  // Engine capacity
  engineSizeCheckbox(event) {
    let container = this.engineCapcityTarget;
    let checkboxes = container.querySelectorAll("input");
    let selected = [];
    checkboxes.forEach((ch) => {
      let value = parseInt(ch.dataset.inputValue, 10);
      if (ch.checked) {
        if (value && selected.indexOf(value) === -1) {
          selected.push(value);
        }
      } else {
        if (value && selected.indexOf(value) >= 0) {
          selected.splice(selected.indexOf(value), 1);
        }
      }
    });

    if (selected.length) {
      this.search_values["engine_size"][0] = [Math.max(...selected)];
    } else {
      this.search_values["engine_size"][0] = [];
    }

    this.updateButtonSearchResult(this.search_values);
  }

  updateEngineSizeCheckboxes(values) {
    if (!values) {
      return;
    }

    if (typeof values === "string") {
      values = values.split(",");
    }

    if (values.length) {
      let target = document.getElementById(
        `engine_capacity_${values}-checkbox`
      );
      if (target) {
        target.checked = true;
        this.search_values["engine_size"][0] = values;
        this.updateButtonSearchResult(this.search_values);
      }
    }
  }

  // Saving pages state and updating after reload
  paginationNavigation(event) {
    let target_page = event.target;
    let page_number = target_page.textContent;
    let target_href = target_page.href;
    if (target_href) {
      let query_params = target_href.substring(target_href.indexOf("?"));
      let url_params = new URLSearchParams(query_params).get("page");
      if (url_params && parseInt(url_params, 10)) {
        let search_terms = this.getSearchDataFromStorage(
          this.SEARCH_STORAGE_KEY
        );
        if (!search_terms) {
          search_terms = { ...this.search_values };
        }
        search_terms["page"] = [url_params];
        this.constructSearchQuery(search_terms);
        document.getElementById("cars-grid").scrollIntoView();
      }
    }
  }

  // Other pages that are using search
  hideSearchBarFromSpecificPage() {
    let current_path = window.location.pathname;
    let search_bar_container = document.getElementById("minimize-search-bar");
    if (current_path && search_bar_container) {
      let path_name_splitted = current_path.split("/");
      if (
        path_name_splitted.indexOf("admin") > 0 &&
        path_name_splitted.indexOf("pages") > 0
      ) {
        search_bar_container.classList.remove("invisible");
      }
    }
  }

  // Removing min or max from search filters keys
  removeMinMaxExtension(word) {
    let splitted_word = word.split("_");
    if (splitted_word.indexOf("min") >= 0) {
      return splitted_word.filter((w) => w !== "min").join("_");
    } else if (splitted_word.indexOf("max") >= 0) {
      return splitted_word.filter((w) => w !== "max").join("_");
    }

    return null;
  }

  keyWithMinMax() {
    return [
      "price_min",
      "price_max",
      "monthly_min",
      "monthly_max",
      "year_min",
      "year_max",
      "mileage_min",
      "mileage_max",
      "seats_min",
      "seats_max",
      "total_weight_max",
      "total_weight_min",
      "towing_brakes_max",
      "towing_brakes_min",
      "towing_no_brakes_max",
      "towing_no_brakes_min",
    ];
  }

  sortedSearchFilterParams(available_filters_params) {
    let sorted_items = removeFieldsFromObj(available_filters_params, [
      "page",
      "order",
      "page_details",
      "search",
      "model",
      "page_type",
      "page_name",
      "promotion_id",
    ]);

    return sorted_items;
  }

  // Displaying available search params
  displayAvailableSearchParams(available_filters_params) {
    const filter_params = [
      "base_type",
      "make",
      "body_type",
      "price",
      "monthly",
      "fuel",
      "year",
      "mileage",
      "gear",
      "drive_type",
      "engine_capacity",
      "seats",
      "total_weight",
      "towing_brakes",
      "towing_no_brakes",
      "department_location",
    ];
    const params = this.sortedSearchFilterParams(available_filters_params);
    const keys_with_min_max = this.keyWithMinMax();

    let keys = Object.keys(params).filter((key) => params[key].length > 0);

    let filtered_keys = keys.map((key) => {
      if (
        key.split("_").indexOf("min") >= 0 ||
        key.split("_").indexOf("max") >= 0
      ) {
        return this.removeMinMaxExtension(key);
      }
      return key;
    });

    let final_set = [...new Set([...filtered_keys])];

    filter_params.forEach((fs) => {
      let num_element = document.getElementById(`count-circle-${fs}`);
      let text_element = document.getElementById(`selected-properties-${fs}`);
      if (final_set.indexOf(fs) >= 0) {
        //let element_id = fs === 'engine_size' ? 'engine_capacity' : fs;
        //let num_element = document.getElementById(`count-circle-${element_id }`);
        //let text_element = document.getElementById(`selected-properties-${element_id }`);
        let item_with_min_max = "";
        let min_val = `${fs}_min`;
        let max_val = `${fs}_max`;

        if (
          keys_with_min_max.indexOf(min_val) >= 0 ||
          keys_with_min_max.indexOf(min_val) >= 0
        ) {
          let item_min = `${params[min_val]}`;
          let item_max = `${params[max_val]}`;
          let val_extension =
            fs === "price" || fs === "monthly"
              ? "€"
              : fs === "year"
              ? ""
              : fs === "mileage"
              ? "km"
              : fs === "seats"
              ? "hlö"
              : fs === "total_weight" ||
                fs === "towing_brakes" ||
                fs === "towing_no_brakes"
              ? "kg"
              : "";
          let min_item_value = item_min ? item_min : "0";
          let max_item_value = item_max ? item_max : "";
          text_element.textContent = `${min_item_value} ${val_extension} - ${max_item_value} ${val_extension}`;
          text_element.classList.remove("d-none");
          text_element.classList.add("d-block");
          num_element.classList.add("d-none");
        } else {
          let search_properties = params[fs];
          num_element.textContent = search_properties.length;
          if (num_element.classList.contains("d-none")) {
            num_element.classList.remove("d-none");
            num_element.classList.add("d-block");
          }
          let text_cont_val = search_properties.join(",");
          text_element.textContent =
            fs === "engine_size" ? `ale ${text_cont_val}.0` : text_cont_val;
        }
      } else {
        text_element.textContent = "";
        num_element.textContent = "";
        replaceClassName(text_element, "d-block", "d-none");
        replaceClassName(num_element, "d-block", "d-none");
      }
    });
  }

  // Changes onClick Arrow down or up
  changeCollapseArrow(event) {
    let icon_1 = `<i class="fas fa-chevron-`;
    let icon_2 = `fs-12 text-gray22 pe-none"></i>`;
    let element = event.target;
    let arrow_type = element.dataset.arrow;
    if (arrow_type === "up") {
      element.innerHTML = `${icon_1}up ${icon_2}`;
      element.dataset.arrow = "down";
    } else if (arrow_type === "down") {
      element.innerHTML = `${icon_1}down ${icon_2}`;
      element.dataset.arrow = "up";
    }
  }

  roundedFilterSliderBtn() {
    return `  <button class="d-md-none button outline-none shadow-none border-0 align-self-start box-size-40 rounded-circle bg-primary text-white d-flex align-items-center justify-content-center mb-2 me-2" data-action="click->search#toggleExactSearch">
                <i class="fas fa-sliders-h pe-none"></i>
              </button>
              <button class="align-self-start d-none d-md-flex align-items-center justify-content-center border-0 bg-primary text-white fs-5 mb-2 me-3 px-3 outline-none rounded-pill" style="height: 32px" data-action="click->search#toggleExactSearch">
                <i class="fas fa-sliders-h d-inline-block pe-none"></i>
                <span class="d-none ms-2 fs-12 pe-none d-sm-block d-md-block d-lg-block d-xl-block d-xxl-block" style="white-space: nowrap">Tarkka haku</span>
              </button>
              <div class="d-flex align-items-center flex-sm-wrap">`;
  }

  multipleFiltersStart(filter_name, keyToFi) {
    return `<div class="rounded-pill primary-light-40 d-flex align-items-center ps-3 me-2 mb-2">
      <button class="btn bg-transparent border-0 outline-none shadow-none text-primary me-2 p-0 my-1" data-filter-name="${filter_name}" data-action="click->search#updateFilterSliderParamsMultiple"><i class="fas fa-times pe-none"></i></button>
      <div class="d-flex align-items-center flex-sm-wrap"> <p class="mb-0 me-2 text-primary">${keyToFi}</p>`;
  }

  roundedFilterSliderItem(name, item_type, filter_names) {
    let item_type_class = item_type === "single" ? "mb-2" : "my-1";
    let [item_1, item_2] = filter_names;
    return `<div class="height-32 rounded-pill bg-primary text-white d-flex align-items-center justify-content-between fs-12 p-2 me-1 ${item_type_class}">
          <span class="d-block me-3 text-nowrap">${name}</span>
          <button class="btn bg-transparent text-white border-0 outline-none shadow-none p-0" data-filter-name="${item_1}" data-filter-item="${item_2}" data-action="click->search#updateFilterSliderParamsSingle"><i class="fas fa-times pe-none"></i></button>
        </div>`;
  }

  searchAgentAndResetBtns() {
    let shareBtn = this.shareButton();
    return `<button class="height-32 bg-transparent text-primary text-center border-0 outline-none shadow-none fs-12 px-2 me-1 mb-2" data-bs-toggle="modal" data-bs-target="#searchAgentModal">
              Luo hakuvahti
            </button>
            <button class="outline-none border-0 bg-transparent fs-12 px-2 me-1 mb-2" data-slider-btn="Yes" data-action="click->search#clearFilter">
              <i class="fas fa-redo-alt text-danger pe-none"></i>
              <span class="d-inline-block text-danger fs-12 pe-none">Nollaa</span>
            </button>
            ${shareBtn}
            `;
  }

  // Updating and showing selected search filter parameters
  showUpdateSearchParameters(params) {
    //const is_landing_page = document.getElementById("minimize-search-bar");
    //if(is_landing_page){
    //  return;
    //}
    const level_one_filter = this.sortedSearchFilterParams(params);
    level_one_filter["model"] = [];
    let filtered_params = Object.keys(level_one_filter).filter(
      (key) => params[key].length > 0
    );
    //if (filtered_params.length === 0) {
    //  return;
    //}
    let filters_with_min_max = [
      "price",
      "monthly",
      "year",
      "mileage",
      "seats",
      "total_weight",
      "towing_brakes",
      "towing_no_brakes",
    ];
    let main_container_element = document.getElementById(
      "search_filters_display"
    );
    let filter_params_html = "";
    filter_params_html += this.roundedFilterSliderBtn();
    let filter_with_no_min_max_key = filtered_params.map((param) => {
      let splitted = param.split("_");
      if (splitted.indexOf("min") >= 0 || splitted.indexOf("max") >= 0) {
        return this.removeMinMaxExtension(param);
      }
      return param;
    });

    let unique_filters = [...new Set([...filter_with_no_min_max_key])]; // ["Make", "Model"]

    this.createSearchAgentValues(params);
    unique_filters.forEach((parameter) => {
      let filter_parameter = params[parameter];
      if (filters_with_min_max.indexOf(parameter) >= 0) {
        let obj_key_max = `${parameter}_max`;
        let obj_key_min = `${parameter}_min`;
        if (params[obj_key_max]) {
          filter_parameter = params[obj_key_max];
        } else if (params[obj_key_min]) {
          filter_parameter = params[obj_key_min];
        }
      } else {
        filter_parameter = params[parameter];
      }
      let keyToFi = carPropertiesToFi(parameter);
      let filter_with_multiple_params = this.multipleFiltersStart(
        parameter,
        keyToFi
      );
      if (filter_parameter && filter_parameter.length > 1) {
        filter_parameter.forEach((paremeter_item) => {
          filter_with_multiple_params += this.roundedFilterSliderItem(
            paremeter_item,
            "multiple",
            [parameter, paremeter_item]
          );
        });
        filter_with_multiple_params += `</div></div>`;
        filter_params_html += filter_with_multiple_params;
      } else if (filter_parameter && filter_parameter.length === 1) {
        let filter_with_single_param = filter_parameter[0];
        let min_max_common_name = this.removeMinMaxExtension(parameter);
        let has_min_max_val = filters_with_min_max.indexOf(parameter) >= 0;
        if (has_min_max_val) {
          let min_val = `${parameter}_min`;
          let max_val = `${parameter}_max`;
          let current_values = "";
          let current_max_val = params[max_val];
          let current_min_val = params[min_val];
          if (current_min_val.length > 0 && current_max_val.length > 0) {
            current_values = `${current_min_val} - ${current_max_val}`;
          } else if (current_max_val.length > 0) {
            current_values = current_max_val;
          } else if (current_min_val.length > 0) {
            current_values = current_min_val;
          }
          filter_params_html += this.roundedFilterSliderItem(
            current_values,
            "single",
            [parameter, ""]
          );
        } else {
          filter_params_html += this.roundedFilterSliderItem(
            filter_with_single_param,
            "single",
            [parameter, ""]
          );
        }
      }
    });

    filter_params_html += `${this.searchAgentAndResetBtns()}</div>`;
    if (main_container_element) {
      main_container_element.innerHTML = filter_params_html;
    }
  }

  // Updating slider filters
  updateFilterSliderParamsMultiple(event) {
    let element = event.target;
    let filter_name = element.dataset.filterName;
    if (filter_name) {
      this.search_values[filter_name] = [];
      location.reload();
      this.updateButtonSearchResult(this.search_values);
      let show_result_btn_href = document.getElementById("show-result-btn");
      if (show_result_btn_href) {
        show_result_btn_href.click();
      }
      this.updateExistingSearchQuery();
    }
  }

  updateFilterSliderParamsSingle(event) {
    let element = event.target;
    let filter_name = element.dataset.filterName;
    let filter_item = element.dataset.filterItem;
    let selected = null;

    if (filter_name && filter_item) {
      let current_values = this.search_values[filter_name];
      let filtered_values = current_values.filter((val) => val !== filter_item);
      // current_values.map(cv => {
      //   let checkbox = document.getElementById(`${cv}-checkbox`);
      //   if(checkbox && filtered_values.indexOf(cv) < 0){
      //     checkbox.checked = false;
      //     let arrows = document.getElementById(`${cv}-arrows`)
      //     if(arrows){
      //       arrows.click();
      //     }
      //   }
      // })
      this.search_values[filter_name] = filtered_values;
      selected = this.filter_data[filter_item];
    } else if (filter_name) {
      let min_val = `${filter_name}_min`;
      let max_val = `${filter_name}_max`;
      if (this.search_values[filter_name]) {
        selected = this.filter_data[this.search_values[filter_name][0]];
      }
      if (this.search_values[min_val] || this.search_values[max_val]) {
        this.search_values[min_val] = [];
        this.search_values[max_val] = [];
      } else {
        this.search_values[filter_name] = [];
      }
    }

    // Remove current make model
    if (selected && this.search_values["model"]) {
      let model_name = selected.map((model) => model.name);
      let filtered_model = this.search_values["model"].filter(
        (model) => model_name.indexOf(model) < 0
      );
      this.search_values["model"] = filtered_model;
    }

    this.updateButtonSearchResult(this.search_values);
    location.reload();
    //
    // let show_result_btn_href = document.getElementById("show-result-btn");
    // if (show_result_btn_href) {
    //   show_result_btn_href.click();
    // }
  }

  createSearchAgentValues(params) {
    let result_btn = document.getElementById("show-result-btn");
    let search_agent_link = document.getElementById("search_agent_link");
    let search_agent_search_name = document.getElementById(
      "search_agent_search_name"
    );
    let total_vehicles = document.getElementById("total-vehicles").textContent;
    let search_agent_name = "";

    if (result_btn && search_agent_link) {
      search_agent_link.value = result_btn.href;
    }

    if (params["make"]) {
      let make = params["make"];
      search_agent_name += make.join(" - ");
    } else if (params["price_min"]) {
      search_agent_name += ` - ${params["price_min"]}`;
    } else if (params["price_max"]) {
      search_agent_name += ` - ${params["price_max"]}`;
    } else {
      search_agent_name += `Lempiautot`;
    }

    if (search_agent_search_name) {
      search_agent_search_name.value = search_agent_name;
    }
  }

  // Car Promotions
  updateCarPromotions() {
    let car_promotions_main = document.getElementById("car-promotions-main");
    let result_btn = document.getElementById("show-result-btn");
    if (car_promotions_main && result_btn) {
      let promotion_type = document.getElementById(
        "car_promotion_promotion_type"
      );
      let promotion_link = document.getElementById("car_promotion_link");
      promotion_link.value = result_btn.href;
      Rails.ajax({
        type: "get",
        url: `/admin/car_promotions/show_selected_filters${this.constructed_search_params}&page_type=${promotion_type.value}`,
        success: function (response) {
          let { status, data } = response;
          if (status === "SUCCESS") {
            let cars_grid = document.getElementById("cars-section");
            cars_grid.innerHTML = data;
          }
        },
      });
    }
  }

  // dataLayer product click
  updateProductionObj(e) {
    let ele = e.target;
    let link_tag = ele.closest("a");
    if (link_tag) {
      let make = link_tag.dataset.make;
      let model = link_tag.dataset.model;
      let easy_id = link_tag.dataset.easyId;
      let body_type = link_tag.dataset.bodyType;
      let drive_type = link_tag.dataset.driveType;
      let price = link_tag.dataset.price;
      let id = link_tag.dataset.carId;
      let year = link_tag.dataset.year;
      let mileage = link_tag.dataset.mileage;
      let hitch = link_tag.dataset.hitch;
      let color = link_tag.dataset.color;
      let monthly_payment = link_tag.dataset.monthlyPayment;
      let combined = link_tag.dataset.combined;
      if (window.dataLayer) {
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        dataLayer.push({
          event: "productClick",
          ecommerce: {
            click: {
              actionField: { list: "Search Results" }, // Optional list property.
              products: [
                {
                  name: `${make} ${model}`, // Name or ID is required.
                  id: parseInt(easy_id, 10),
                  price: price,
                  brand: `${make}`,
                  category: `${body_type}`,
                  variant: `${drive_type}`,
                  position: parseInt(id, 10),
                  metric1: year, //Integer, ie '2015'
                  metric2: mileage, //Integer, ie '149203'
                  metric3: price, //Decimal, ie '14900.00'
                  dimension7: `${make}`,
                  dimension8: `${model}`,
                  dimension9: `${color}`,
                  dimension10: `${drive_type}`,
                  dimension11: hitch,
                  monthly_payment: monthly_payment,
                  fuel_consumption_combined: combined,
                },
              ],
            },
          },
          eventCallback: function () {
            document.location = link_tag.href;
          },
        });
      }

      sessionStorage.setItem("productClick", location.pathname);
    }

    return false;
  }

  // Observer
  createObserver() {
    let boxElement = document.querySelector("#cars-grid");
    if (boxElement) {
      let cols = boxElement.querySelectorAll(".car-card-main-container");
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          let content = entry.target.children[0];
          if (entry.isIntersecting) {
            content.classList.remove("d-none");
          }
        });
      }, {});

      cols.forEach((col) => {
        observer.observe(col);
      });
    }
  }

  scrollElementCarToView() {
    let id = sessionStorage.getItem("3nvU62110TyO2d@t8fdGYZcg");
    if (id) {
      setTimeout(() => {
        let card = document.getElementById(id);
        if (card) {
          card.scrollIntoView();
          sessionStorage.removeItem("3nvU62110TyO2d@t8fdGYZcg");
        }
      }, 1000);
    }
  }

  setSharingUrls() {
    let showBtn = document.getElementById("show-result-btn");
    let searchParams = showBtn.href.split("?")[1];
    let shareModal = document.getElementById("smeShareModal");
    let tags = shareModal.getElementsByTagName("a");
    let input = shareModal.getElementsByTagName("input")[0];
    let currentUrl = window.location.href.split("?")[0];
    let smeShareUrl = {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}?${searchParams}`,
      twitter: `https://twitter.com/intent/tweet?text=${currentUrl}?${searchParams}`,
      whatsapp: `https://api.whatsapp.com/send?text=${currentUrl}?${searchParams}`,
      messenger: `https://www.facebook.com/messages/t/${currentUrl}?${searchParams}`,
      email: `mailto:?subject=Autolle.com hakutulokset &amp;body=${currentUrl}?${searchParams}`,
    };
    input.value = currentUrl + "?" + searchParams;
    Array.from(tags).map((tag) => {
      let name = tag.getAttribute("data-name");
      tag.setAttribute("href", smeShareUrl[name]);
    });
  }

  shareButton() {
    let main = document.getElementsByTagName("main")[0];
    if (main) {
      let pageName = main.getAttribute("data-page-name");
      if (pageName && ["lease", "regular"].includes(pageName)) {
        return `<button class="outline-none border-0 bg-transparent fs-14 px-2 me-1 mb-2" data-bs-toggle="modal" data-bs-target="#smeShareModal" data-action="click->search#setSharingUrls">
              <i class="fas fa-share-alt-square text-dark pe-none"></i>
              <span class="d-inline-block text-dark fs-14 pe-none">Jaa</span>
            </button>`;
      }
    }

    return "";
  }
}
